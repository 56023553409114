import { find } from 'lodash';
import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_REQUEST_ERROR,
  CATEGORY_LIST_REQUEST_SUCCESS,
  CATEGORY_CREATE,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_ERROR,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_ERROR,
  CATEGORY_DELETE,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_ERROR,
} from '@/store/actions/category';
import { handleRequestError } from '@/utils/error';
import Repository, { CATEGORIES } from '@/repositories/RepositoryFactory';

const CategoriesRepository = Repository.get(CATEGORIES);

const state = {
  status: '',
  category: {},
  categoryList: [],
  categoryListMeta: {},
  categoryListStatus: '',
};

const getters = {
  categoryStatus: state => state.status,
  categoryList: state => state.categoryList,
  categoryListMeta: state => state.categoryListMeta,
  categoryListStatus: state => state.categoryListStatus,
  getCategoryById: (state, getters) => id => {
    if (getters.categoryList.length > 0) {
      return find(getters.categoryList, { id });
    }
    return state.category;
  },
};

const actions = {
  [CATEGORY_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(CATEGORY_LIST_REQUEST);
    try {
      const response = await CategoriesRepository.get(payload);
      commit(CATEGORY_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CATEGORY_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [CATEGORY_CREATE]: async ({ commit }, payload) => {
    commit(CATEGORY_CREATE);
    try {
      await CategoriesRepository.create(payload);
      commit(CATEGORY_CREATE_SUCCESS);
    } catch (error) {
      commit(CATEGORY_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [CATEGORY_UPDATE]: async ({ commit }, { id, data }) => {
    commit(CATEGORY_UPDATE);
    try {
      await CategoriesRepository.update(id, data);
      commit(CATEGORY_UPDATE_SUCCESS);
    } catch (error) {
      commit(CATEGORY_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [CATEGORY_DELETE]: async ({ commit }, id) => {
    commit(CATEGORY_DELETE);
    try {
      await CategoriesRepository.delete(id);
      commit(CATEGORY_DELETE_SUCCESS);
    } catch (error) {
      commit(CATEGORY_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [CATEGORY_LIST_REQUEST]: state => {
    state.categoryListStatus = 'loading';
  },
  [CATEGORY_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.categoryListStatus = 'success';
    state.categoryList = payload.data;
    if (payload.meta) {
      state.categoryListMeta = payload.meta;
    }
  },
  [CATEGORY_LIST_REQUEST_ERROR]: state => {
    state.categoryListStatus = 'error';
  },
  [CATEGORY_CREATE]: state => {
    state.status = 'loading';
  },
  [CATEGORY_CREATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [CATEGORY_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [CATEGORY_UPDATE]: state => {
    state.status = 'loading';
  },
  [CATEGORY_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [CATEGORY_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [CATEGORY_DELETE]: state => {
    state.status = 'deleting';
  },
  [CATEGORY_DELETE_SUCCESS]: state => {
    state.status = 'deleted';
  },
  [CATEGORY_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
