export const SOCIAL_LIST_REQUEST = 'SOCIAL_LIST_REQUEST';
export const SOCIAL_LIST_REQUEST_SUCCESS = 'SOCIAL_LIST_REQUEST_SUCCESS';
export const SOCIAL_LIST_REQUEST_ERROR = 'SOCIAL_LIST_REQUEST_ERROR';

export const SOCIAL_CREATE = 'SOCIAL_CREATE';
export const SOCIAL_CREATE_SUCCESS = 'SOCIAL_CREATE_SUCCESS';
export const SOCIAL_CREATE_ERROR = 'SOCIAL_CREATE_ERROR';

export const SOCIAL_UPDATE = 'SOCIAL_UPDATE';
export const SOCIAL_UPDATE_SUCCESS = 'SOCIAL_UPDATE_SUCCESS';
export const SOCIAL_UPDATE_ERROR = 'SOCIAL_UPDATE_ERROR';

export const SOCIAL_DELETE = 'SOCIAL_DELETE';
export const SOCIAL_DELETE_SUCCESS = 'SOCIAL_DELETE_SUCCESS';
export const SOCIAL_DELETE_ERROR = 'SOCIAL_DELETE_ERROR';
