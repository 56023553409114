<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
    @shown="onShown"
  >
    <b-form @submit.prevent="onSave">
      <InputSelect
        class="category"
        :label="$t('label.category')"
        size="lg"
        v-model="$v.form.categoryId.$model"
        :vuelidate="$v.form.categoryId"
        :options="categoryList"
        :disabled="isLoadingCategoryList"
      />

      <InputString
        class="name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />

      <InputText
        class="embed"
        label="Embed"
        size="lg"
        v-model="$v.form.embed.$model"
        :vuelidate="$v.form.embed"
      />

      <InputText
        class="description"
        :label="$t('label.description')"
        size="lg"
        :rows="5"
        v-model="$v.form.description.$model"
        :vuelidate="$v.form.description"
      />

      <InputString
        class="duration"
        label="Видео урт /01:30:45/"
        size="lg"
        v-model="$v.form.duration.$model"
        :vuelidate="$v.form.duration"
        v-mask="timeMask"
      />

      <b-row class="mb-3">
        <b-col cols="6" sm="6">
          <InputLabel>{{ $t('label.image') }}</InputLabel>
          <InputPhoto
            :is-loading="isUploadingPhoto"
            :photo="form.file"
            :vuelidate="$v.form.file"
            @change="onChangePhoto"
            @remove="onDeletePhoto"
          />
        </b-col>
      </b-row>

      <InputRadioButton
        class="status"
        label="Үнэтэй эсэх"
        size="lg"
        v-model="form.isFree"
        :options="statusOptions"
      />

      <InputRadioButton
        class="status"
        label="Онцлох"
        size="lg"
        v-model="form.isFeature"
        :options="statusOptions"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import editModalMixin from '@/mixins/editModalMixin';
import { required } from 'vuelidate/lib/validators';
import { CONTENT_CREATE, CONTENT_UPDATE } from '@/store/actions/content';
import { CATEGORY_LIST_REQUEST } from '@/store/actions/category';
import { FILE_UPLOAD } from '@/store/actions/file';
import { YES_OR_NO_OPTIONS } from '@/utils/constants';
import ModalMain from '@/components/ui/ModalMain';
import InputSelect from '@/components/ui/input/InputSelect';
import InputString from '@/components/ui/input/InputString';
import InputText from '@/components/ui/input/InputText';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputLabel from '@/components/ui/input/InputLabel';
import InputRadioButton from '@/components/ui/input/InputRadioButton';

const defaultValuesForForm = () => ({
  name: '',
  categoryId: null,
  file: null,
  embed: '',
  description: '',
  isFree: false,
  isFeature: false,
  duration: '',
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
      statusOptions: YES_OR_NO_OPTIONS,
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
      categoryId: {
        required,
      },
      file: {
        required,
      },
      embed: {
        required,
      },
      description: {
        required,
      },
      duration: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters([
      'contentStatus',
      'getFileUpload',
      'categoryList',
      'categoryListStatus',
      'fileDeleteStatus',
    ]),
    isLoading() {
      return this.contentStatus === 'loading';
    },
    isLoadingCategoryList() {
      return this.categoryListStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? 'Контент нэмэх' : 'Контент засах';
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
  },

  methods: {
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      const seconds = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ':', ...minutes, ':', ...seconds] : hours;
    },
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            categoryId: this.formData.categoryId,
            file: this.formData.file,
            embed: this.formData.embed,
            description: this.formData.description,
            isFree: !!this.formData.isFree,
            isFeature: !!this.formData.isFeature,
            duration: this.formData.duration,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onShown() {
      const payload = {
        limit: 100,
        page: 1,
        filter: {},
      };
      this.$store.dispatch(CATEGORY_LIST_REQUEST, payload);
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = { ...this.form, fileId: this.form.file ? this.form.file.id : null };

      if (isNil(this.formData)) {
        await this.$store.dispatch(CONTENT_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(CONTENT_UPDATE, payload);
      }
      if (this.contentStatus === 'success') {
        this.hideEditModal();
      }
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.file = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    async onDeletePhoto() {
      // await this.$store.dispatch(FILE_DELETE, this.form.file.id);
      // if (this.fileDeleteStatus === 'success') {
      this.form.file = null;
      // }
    },
  },

  components: {
    ModalMain,
    InputSelect,
    InputString,
    InputPhoto,
    InputLabel,
    InputRadioButton,
    InputText,
  },
};
</script>
