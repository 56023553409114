export const TYPE_LIST_REQUEST = 'TYPE_LIST_REQUEST';
export const TYPE_LIST_REQUEST_SUCCESS = 'TYPE_LIST_REQUEST_SUCCESS';
export const TYPE_LIST_REQUEST_ERROR = 'TYPE_LIST_REQUEST_ERROR';

export const TYPE_CREATE = 'TYPE_CREATE';
export const TYPE_CREATE_SUCCESS = 'TYPE_CREATE_SUCCESS';
export const TYPE_CREATE_ERROR = 'TYPE_CREATE_ERROR';

export const TYPE_UPDATE = 'TYPE_UPDATE';
export const TYPE_UPDATE_SUCCESS = 'TYPE_UPDATE_SUCCESS';
export const TYPE_UPDATE_ERROR = 'TYPE_UPDATE_ERROR';

export const TYPE_DELETE = 'TYPE_DELETE';
export const TYPE_DELETE_SUCCESS = 'TYPE_DELETE_SUCCESS';
export const TYPE_DELETE_ERROR = 'TYPE_DELETE_ERROR';
