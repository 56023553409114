import { find } from 'lodash';
import {
  GALLERY_REQUEST,
  GALLERY_REQUEST_ERROR,
  GALLERY_REQUEST_SUCCESS,
  GALLERY_LIST_REQUEST,
  GALLERY_LIST_REQUEST_ERROR,
  GALLERY_LIST_REQUEST_SUCCESS,
  GALLERY_CREATE,
  GALLERY_CREATE_SUCCESS,
  GALLERY_CREATE_ERROR,
  GALLERY_UPDATE,
  GALLERY_UPDATE_SUCCESS,
  GALLERY_UPDATE_ERROR,
  GALLERY_DELETE,
  GALLERY_DELETE_SUCCESS,
  GALLERY_DELETE_ERROR,
} from '@/store/actions/gallery';
import { handleRequestError } from '@/utils/error';
import Repository, { GALLERIES } from '@/repositories/RepositoryFactory';

const GalleriesRepository = Repository.get(GALLERIES);

const state = {
  status: '',
  gallery: {},
  galleryList: [],
  galleryListMeta: {},
  galleryListStatus: '',
};

const getters = {
  gallery: state => state.gallery,
  galleryStatus: state => state.status,
  galleryList: state => state.galleryList,
  galleryListMeta: state => state.galleryListMeta,
  galleryListStatus: state => state.galleryListStatus,
  getGalleryById: (state, getters) => id => {
    if (getters.galleryList.length > 0) {
      return find(getters.galleryList, { id });
    }
    return state.gallery;
  },
};

const actions = {
  [GALLERY_REQUEST]: async ({ commit }, id) => {
    commit(GALLERY_REQUEST);
    try {
      const response = await GalleriesRepository.show(id);
      commit(GALLERY_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(GALLERY_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [GALLERY_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(GALLERY_LIST_REQUEST);
    try {
      const response = await GalleriesRepository.get(payload);
      commit(GALLERY_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(GALLERY_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [GALLERY_CREATE]: async ({ commit }, payload) => {
    commit(GALLERY_CREATE);
    try {
      await GalleriesRepository.create(payload);
      commit(GALLERY_CREATE_SUCCESS);
    } catch (error) {
      commit(GALLERY_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [GALLERY_UPDATE]: async ({ commit }, { id, data }) => {
    commit(GALLERY_UPDATE);
    try {
      await GalleriesRepository.update(id, data);
      commit(GALLERY_UPDATE_SUCCESS);
    } catch (error) {
      commit(GALLERY_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [GALLERY_DELETE]: async ({ commit }, id) => {
    commit(GALLERY_DELETE);
    try {
      await GalleriesRepository.delete(id);
      commit(GALLERY_DELETE_SUCCESS);
    } catch (error) {
      commit(GALLERY_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [GALLERY_REQUEST]: state => {
    state.status = 'loading';
  },
  [GALLERY_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.gallery = payload.data;
  },
  [GALLERY_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [GALLERY_LIST_REQUEST]: state => {
    state.galleryListStatus = 'loading';
  },
  [GALLERY_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.galleryListStatus = 'success';
    state.galleryList = payload.data;
    if (payload.meta) {
      state.galleryListMeta = payload.meta;
    }
  },
  [GALLERY_LIST_REQUEST_ERROR]: state => {
    state.galleryListStatus = 'error';
  },
  [GALLERY_CREATE]: state => {
    state.status = 'loading';
  },
  [GALLERY_CREATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [GALLERY_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [GALLERY_UPDATE]: state => {
    state.status = 'loading';
  },
  [GALLERY_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [GALLERY_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [GALLERY_DELETE]: state => {
    state.status = 'deleting';
  },
  [GALLERY_DELETE_SUCCESS]: state => {
    state.status = 'deleted';
  },
  [GALLERY_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
