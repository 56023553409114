import { find } from 'lodash';
import {
  CONTRIBUTION_REQUEST,
  CONTRIBUTION_REQUEST_ERROR,
  CONTRIBUTION_REQUEST_SUCCESS,
  CONTRIBUTION_LIST_REQUEST,
  CONTRIBUTION_LIST_REQUEST_ERROR,
  CONTRIBUTION_LIST_REQUEST_SUCCESS,
  CONTRIBUTION_CREATE,
  CONTRIBUTION_CREATE_SUCCESS,
  CONTRIBUTION_CREATE_ERROR,
  CONTRIBUTION_UPDATE,
  CONTRIBUTION_UPDATE_SUCCESS,
  CONTRIBUTION_UPDATE_ERROR,
  CONTRIBUTION_DELETE,
  CONTRIBUTION_DELETE_SUCCESS,
  CONTRIBUTION_DELETE_ERROR,
} from '@/store/actions/contribution';
import { handleRequestError } from '@/utils/error';
import Repository, { CONTRIBUTIONS } from '@/repositories/RepositoryFactory';

const ContributionsRepository = Repository.get(CONTRIBUTIONS);

const state = {
  status: '',
  contribution: {},
  contributionList: [],
  contributionListMeta: {},
  contributionListStatus: '',
};

const getters = {
  contribution: state => state.contribution,
  contributionStatus: state => state.status,
  contributionList: state => state.contributionList,
  contributionListMeta: state => state.contributionListMeta,
  contributionListStatus: state => state.contributionListStatus,
  getContributionById: (state, getters) => id => {
    if (getters.contributionList.length > 0) {
      return find(getters.contributionList, { id });
    }
    return state.contribution;
  },
};

const actions = {
  [CONTRIBUTION_REQUEST]: async ({ commit }, id) => {
    commit(CONTRIBUTION_REQUEST);
    try {
      const response = await ContributionsRepository.show(id);
      commit(CONTRIBUTION_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CONTRIBUTION_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [CONTRIBUTION_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(CONTRIBUTION_LIST_REQUEST);
    try {
      const response = await ContributionsRepository.get(payload);
      commit(CONTRIBUTION_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CONTRIBUTION_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [CONTRIBUTION_CREATE]: async ({ commit }, payload) => {
    commit(CONTRIBUTION_CREATE);
    try {
      await ContributionsRepository.create(payload);
      commit(CONTRIBUTION_CREATE_SUCCESS);
    } catch (error) {
      commit(CONTRIBUTION_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [CONTRIBUTION_UPDATE]: async ({ commit }, { id, data }) => {
    commit(CONTRIBUTION_UPDATE);
    try {
      await ContributionsRepository.update(id, data);
      commit(CONTRIBUTION_UPDATE_SUCCESS);
    } catch (error) {
      commit(CONTRIBUTION_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [CONTRIBUTION_DELETE]: async ({ commit }, id) => {
    commit(CONTRIBUTION_DELETE);
    try {
      await ContributionsRepository.delete(id);
      commit(CONTRIBUTION_DELETE_SUCCESS);
    } catch (error) {
      commit(CONTRIBUTION_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [CONTRIBUTION_REQUEST]: state => {
    state.status = 'loading';
  },
  [CONTRIBUTION_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.contribution = payload.data;
  },
  [CONTRIBUTION_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [CONTRIBUTION_LIST_REQUEST]: state => {
    state.contributionListStatus = 'loading';
  },
  [CONTRIBUTION_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.contributionListStatus = 'success';
    state.contributionList = payload.data;
    if (payload.meta) {
      state.contributionListMeta = payload.meta;
    }
  },
  [CONTRIBUTION_LIST_REQUEST_ERROR]: state => {
    state.contributionListStatus = 'error';
  },
  [CONTRIBUTION_CREATE]: state => {
    state.status = 'loading';
  },
  [CONTRIBUTION_CREATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [CONTRIBUTION_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [CONTRIBUTION_UPDATE]: state => {
    state.status = 'loading';
  },
  [CONTRIBUTION_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [CONTRIBUTION_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [CONTRIBUTION_DELETE]: state => {
    state.status = 'deleting';
  },
  [CONTRIBUTION_DELETE_SUCCESS]: state => {
    state.status = 'deleted';
  },
  [CONTRIBUTION_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
