import {
  DASHBOARD_REQUEST,
  DASHBOARD_REQUEST_SUCCESS,
  DASHBOARD_REQUEST_ERROR,
} from '@/store/actions/dashboard';

import { handleRequestError } from '@/utils/error';
import Repository, { DASHBOARD } from '@/repositories/RepositoryFactory';

const DashboardRepository = Repository.get(DASHBOARD);

const state = {
  status: '',
  dashboard: {},
  dashboardMeta: {},
  dashboardStatus: '',
};

const getters = {
  boardStatus: state => state.status,
  dashboard: state => state.dashboard,
  dashboardMeta: state => state.dashboardMeta,
  dashboardStatus: state => state.dashboardStatus,
};

const actions = {
  [DASHBOARD_REQUEST]: async ({ commit }, payload) => {
    commit(DASHBOARD_REQUEST);
    try {
      const response = await DashboardRepository.get(payload);
      commit(DASHBOARD_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(DASHBOARD_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};
const mutations = {
  [DASHBOARD_REQUEST]: state => {
    state.dashboardStatus = 'loading';
  },
  [DASHBOARD_REQUEST_SUCCESS]: (state, payload) => {
    state.dashboardStatus = 'success';
    state.dashboard = payload.data;

    if (payload.meta) {
      state.dashboardMeta = payload.meta;
    }
  },
  [DASHBOARD_REQUEST_ERROR]: state => {
    state.dashboardStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
