<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
    @shown="onShown"
  >
    <b-form @submit.prevent="onSave">
      <InputSelect
        class="position"
        :label="$t('label.position')"
        size="lg"
        v-model="$v.form.position.$model"
        :vuelidate="$v.form.position"
        :options="positions"
        :disabled="isLoadingGroups"
      />

      <InputString
        class="name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />

      <InputString
        class="link"
        :label="$t('label.link')"
        size="lg"
        v-model="$v.form.link.$model"
        :vuelidate="$v.form.link"
      />

      <b-row>
        <b-col cols="12" sm="6">
          <InputDatepicker
            class="start-at"
            :label="$t('label.startAt')"
            size="lg"
            v-model="$v.form.startDate.$model"
            :vuelidate="$v.form.startDate"
          />
        </b-col>
        <b-col cols="12" sm="6">
          <InputDatepicker
            class="end-at"
            :label="$t('label.endAt')"
            size="lg"
            v-model="$v.form.endDate.$model"
            :vuelidate="$v.form.endDate"
            :min="form.startDate"
          />
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col cols="6" sm="6">
          <InputLabel>{{ $t('label.image') }}</InputLabel>
          <InputPhoto
            :is-loading="isUploadingPhoto"
            :photo="form.file"
            :vuelidate="$v.form.file"
            @change="onChangePhoto"
            @remove="onDeletePhoto"
          />
        </b-col>
      </b-row>

      <InputRadioButton
        class="status"
        :label="$t('label.status')"
        size="lg"
        v-model="form.isActive"
        :options="statusOptions"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import editModalMixin from '@/mixins/editModalMixin';
import { required } from 'vuelidate/lib/validators';
import { BANNER_CREATE, BANNER_UPDATE, BANNER_POSITIONS_REQUEST } from '@/store/actions/banner';
import { FILE_UPLOAD } from '@/store/actions/file';
import { STATUS_OPTIONS } from '@/utils/constants';
import ModalMain from '@/components/ui/ModalMain';
import InputSelect from '@/components/ui/input/InputSelect';
import InputString from '@/components/ui/input/InputString';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputLabel from '@/components/ui/input/InputLabel';
import InputRadioButton from '@/components/ui/input/InputRadioButton';
import InputDatepicker from '@/components/ui/input/InputDatepicker';

const defaultValuesForForm = () => ({
  name: '',
  file: null,
  startDate: '',
  endDate: '',
  position: null,
  isActive: false,
  link: '',
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
      statusOptions: STATUS_OPTIONS,
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
      file: {
        required,
      },
      startDate: {
        required,
      },
      endDate: {
        required,
      },
      position: {
        required,
      },
      link: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters([
      'bannerStatus',
      'getFileUpload',
      'bannerPositions',
      'bannerPositionsStatus',
      'fileDeleteStatus',
    ]),
    isLoading() {
      return this.bannerStatus === 'loading';
    },
    isLoadingGroups() {
      return this.bannerPositionsStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? this.$t('banner.newBanner') : this.$t('banner.editBanner');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
    positions() {
      return this.bannerPositions.map(item => ({ id: item, name: item }));
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            link: this.formData.link ? this.formData.link : '',
            file: this.formData.file,
            startDate: this.formData.startDate,
            endDate: this.formData.endDate,
            position: this.formData.position,
            isActive: this.formData.isActive,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onShown() {
      this.$store.dispatch(BANNER_POSITIONS_REQUEST);
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(BANNER_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(BANNER_UPDATE, payload);
      }
      if (this.bannerStatus === 'success') {
        this.hideEditModal();
      }
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.file = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    async onDeletePhoto() {
      // await this.$store.dispatch(FILE_DELETE, this.form.file.id);
      // if (this.fileDeleteStatus === 'success') {
      this.form.file = null;
      // }
    },
  },

  components: {
    ModalMain,
    InputSelect,
    InputString,
    InputPhoto,
    InputLabel,
    InputRadioButton,
    InputDatepicker,
  },
};
</script>
