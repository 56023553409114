export const POLL_LIST_REQUEST = 'POLL_LIST_REQUEST';
export const POLL_LIST_REQUEST_SUCCESS = 'POLL_LIST_REQUEST_SUCCESS';
export const POLL_LIST_REQUEST_ERROR = 'POLL_LIST_REQUEST_ERROR';

export const POLL_CREATE = 'POLL_CREATE';
export const POLL_CREATE_SUCCESS = 'POLL_CREATE_SUCCESS';
export const POLL_CREATE_ERROR = 'POLL_CREATE_ERROR';

export const POLL_UPDATE = 'POLL_UPDATE';
export const POLL_UPDATE_SUCCESS = 'POLL_UPDATE_SUCCESS';
export const POLL_UPDATE_ERROR = 'POLL_UPDATE_ERROR';

export const POLL_DELETE = 'POLL_DELETE';
export const POLL_DELETE_SUCCESS = 'POLL_DELETE_SUCCESS';
export const POLL_DELETE_ERROR = 'POLL_DELETE_ERROR';
