<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="true"
    size="lg"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="facebook"
        :label="$t('label.facebook')"
        size="lg"
        v-model="$v.form.facebook.$model"
        :vuelidate="$v.form.facebook"
      />
      <InputString
        class="messenger"
        :label="$t('label.messenger')"
        size="lg"
        v-model="$v.form.messenger.$model"
        :vuelidate="$v.form.messenger"
      />
      <InputString
        class="phone"
        :label="$t('label.phone')"
        size="lg"
        v-model="$v.form.phone.$model"
        :vuelidate="$v.form.phone"
      />
      <InputString
        class="instagram"
        :label="$t('label.instagram')"
        size="lg"
        v-model="$v.form.instagram.$model"
        :vuelidate="$v.form.instagram"
      />
      <InputString
        class="email"
        :label="$t('label.email')"
        size="lg"
        v-model="$v.form.email.$model"
        :vuelidate="$v.form.email"
      />
      <InputString
        class="youtube"
        :label="$t('label.youtube')"
        size="lg"
        v-model="$v.form.youtube.$model"
        :vuelidate="$v.form.youtube"
      />
      <InputEditor
        class="privacy-policy"
        :label="$t('label.privacyPolicy')"
        size="lg"
        v-model="$v.form.privacyPolicy.$model"
        :vuelidate="$v.form.privacyPolicy"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { PAGE_CREATE, PAGE_UPDATE } from '@/store/actions/page';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
import InputEditor from '@/components/ui/input/InputEditor';

const defaultValuesForForm = () => ({
  aboutUs: 'ABOUT_US',
  facebook: '',
  messenger: '',
  phone: '',
  instagram: '',
  email: '',
  privacyPolicy: '',
  youtube: '',
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations: {
    form: {
      facebook: {
        required,
      },
      messenger: {
        required,
      },
      phone: {
        required,
      },
      instagram: {
        required,
      },
      email: {
        required,
      },
      privacyPolicy: {
        required,
      },
      youtube: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['pageStatus']),
    isLoading() {
      return this.pageStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? this.$t('page.newPage') : this.$t('page.editPage');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            aboutUs: this.formData.aboutUs,
            facebook: this.formData.facebook,
            messenger: this.formData.messenger,
            phone: this.formData.phone,
            instagram: this.formData.instagram,
            email: this.formData.email,
            privacyPolicy: this.formData.privacyPolicy,
            youtube: this.formData.youtube,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(PAGE_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(PAGE_UPDATE, payload);
      }
      if (this.pageStatus === 'success') {
        this.hideEditModal();
      }
    },
  },

  components: {
    ModalMain,
    InputString,
    InputEditor,
  },
};
</script>
