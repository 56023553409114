<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    delete-disabled
    @shown="onShown"
    @ok="onSave"
    @cancel="onCancel"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />
      <InputString
        class="button-text"
        :label="$t('label.buttonText')"
        size="lg"
        v-model="$v.form.buttonText.$model"
        :vuelidate="$v.form.buttonText"
      />
      <InputText
        class="comment"
        :label="$t('label.contributeComment')"
        size="lg"
        v-model="$v.form.comment.$model"
        :vuelidate="$v.form.comment"
      />
      <InputEditor
        class="description"
        :label="$t('label.description')"
        size="lg"
        v-model="$v.form.description.$model"
        :vuelidate="$v.form.description"
      />
      <div>
        <div
          v-for="(price, index) in $v.form.price.$each.$iter"
          :key="`price-${index}`"
          class="mb-3"
        >
          <InputMoney
            :class="`price-${index} mb-1`"
            :label="$t('label.price')"
            size="lg"
            v-model="price.value.$model"
            :vuelidate="price.value"
            :value-as-integer="false"
          />
          <div v-if="form.price && form.price.length > 1" class="text-right">
            <InlineButton @click="removePrice(index)" variant="outline-danger" size="sm">
              {{ $t('text.deleteButton') }}
            </InlineButton>
          </div>
        </div>
        <div class="text-right">
          <InlineButton @click="addPrice">{{ $t('text.addPrice') }}</InlineButton>
        </div>
      </div>
    </b-form>
  </ModalMain>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { CONTRIBUTION_REQUEST, CONTRIBUTION_UPDATE } from '@/store/actions/contribution';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
import InputText from '@/components/ui/input/InputText';
import InputEditor from '@/components/ui/input/InputEditor';
import InputMoney from '@/components/ui/input/InputMoney';
import InlineButton from '@/components/ui/button/InlineButton';

const defaultValuesForForm = () => ({
  name: '',
  buttonText: '',
  comment: '',
  description: '',
  price: [],
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
      buttonText: {
        required,
      },
      comment: {
        required,
      },
      description: {
        required,
      },
      price: {
        required,
        $each: {
          value: { required },
        },
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['contributionStatus', 'contribution']),
    isLoading() {
      return this.contributionStatus === 'loading';
    },
    title() {
      return 'Засах';
    },
    saveButtonTitle() {
      return this.$t('text.updateButton');
    },
  },

  methods: {
    async onShown() {
      await this.$store.dispatch(CONTRIBUTION_REQUEST, 1);
      if (this.contributionStatus === 'success') {
        this.updateForm();
      }
    },
    updateForm() {
      this.form = this.contribution
        ? {
            name: this.contribution.name,
            buttonText: this.contribution.buttonText,
            comment: this.contribution.comment,
            description: this.contribution.description,
            price: this.contribution.price.map(item => {
              return {
                value: parseInt(item, 10),
              };
            }),
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = {
        ...this.form,
        price: this.form.price.map(item => {
          return item.value;
        }),
      };

      const payload = {
        id: 1,
        data,
      };
      await this.$store.dispatch(CONTRIBUTION_UPDATE, payload);
      if (this.contributionStatus === 'success') {
        this.hideEditModal();
      }
    },
    addPrice() {
      this.form.price.push({ value: 0 });
    },
    removePrice(index) {
      this.form.price.splice(index, 1);
    },
  },

  components: {
    ModalMain,
    InputString,
    InputText,
    InputEditor,
    InputMoney,
    InlineButton,
  },
};
</script>
