export const CUSTOMER_LIST_REQUEST = 'CUSTOMER_LIST_REQUEST';
export const CUSTOMER_LIST_REQUEST_SUCCESS = 'CUSTOMER_LIST_REQUEST_SUCCESS';
export const CUSTOMER_LIST_REQUEST_ERROR = 'CUSTOMER_LIST_REQUEST_ERROR';

export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_ERROR = 'CUSTOMER_UPDATE_ERROR';

export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS';
export const CUSTOMER_DELETE_ERROR = 'CUSTOMER_DELETE_ERROR';

export const CUSTOMER_EXPORT = 'CUSTOMER_EXPORT';
export const CUSTOMER_EXPORT_SUCCESS = 'CUSTOMER_EXPORT_SUCCESS';
export const CUSTOMER_EXPORT_ERROR = 'CUSTOMER_EXPORT_ERROR';

export const CHANGE_SUBSCRIPTION = 'CHANGE_SUBSCRIPTION';
export const CHANGE_SUBSCRIPTION_SUCCESS = 'CHANGE_SUBSCRIPTION_SUCCESS';
export const CHANGE_SUBSCRIPTION_ERROR = 'CHANGE_SUBSCRIPTION_ERROR';
