import {
  NEWS_REQUEST,
  NEWS_REQUEST_ERROR,
  NEWS_REQUEST_SUCCESS,
  NEWS_LIST_REQUEST,
  NEWS_LIST_REQUEST_ERROR,
  NEWS_LIST_REQUEST_SUCCESS,
  NEWS_CREATE,
  NEWS_CREATE_SUCCESS,
  NEWS_CREATE_ERROR,
  NEWS_UPDATE,
  NEWS_UPDATE_SUCCESS,
  NEWS_UPDATE_ERROR,
  NEWS_DELETE,
  NEWS_DELETE_SUCCESS,
  NEWS_DELETE_ERROR,
} from '@/store/actions/news';
import { handleRequestError } from '@/utils/error';
import Repository, { NEWS } from '@/repositories/RepositoryFactory';

const NewsRepository = Repository.get(NEWS);

const state = {
  status: '',
  news: {},
  newsList: [],
  newsListMeta: {},
  newsListStatus: '',
};

const getters = {
  news: state => state.news,
  newsStatus: state => state.status,
  newsList: state => state.newsList,
  newsListMeta: state => state.newsListMeta,
  newsListStatus: state => state.newsListStatus,
};

const actions = {
  [NEWS_REQUEST]: async ({ commit }, id) => {
    commit(NEWS_REQUEST);
    try {
      const response = await NewsRepository.show(id);
      commit(NEWS_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(NEWS_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [NEWS_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(NEWS_LIST_REQUEST);
    try {
      const response = await NewsRepository.get(payload);
      commit(NEWS_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(NEWS_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [NEWS_CREATE]: async ({ commit }, payload) => {
    commit(NEWS_CREATE);
    try {
      await NewsRepository.create(payload);
      commit(NEWS_CREATE_SUCCESS);
    } catch (error) {
      commit(NEWS_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [NEWS_UPDATE]: async ({ commit }, { id, data }) => {
    commit(NEWS_UPDATE);
    try {
      await NewsRepository.update(id, data);
      commit(NEWS_UPDATE_SUCCESS);
    } catch (error) {
      commit(NEWS_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [NEWS_DELETE]: async ({ commit }, id) => {
    commit(NEWS_DELETE);
    try {
      await NewsRepository.delete(id);
      commit(NEWS_DELETE_SUCCESS);
    } catch (error) {
      commit(NEWS_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [NEWS_REQUEST]: state => {
    state.status = 'loading';
  },
  [NEWS_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.news = payload.data;
  },
  [NEWS_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [NEWS_LIST_REQUEST]: state => {
    state.newsListStatus = 'loading';
  },
  [NEWS_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.newsListStatus = 'success';
    state.newsList = payload.data;
    if (payload.meta) {
      state.newsListMeta = payload.meta;
    }
  },
  [NEWS_LIST_REQUEST_ERROR]: state => {
    state.newsListStatus = 'error';
  },
  [NEWS_CREATE]: state => {
    state.status = 'loading';
  },
  [NEWS_CREATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [NEWS_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [NEWS_UPDATE]: state => {
    state.status = 'loading';
  },
  [NEWS_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [NEWS_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [NEWS_DELETE]: state => {
    state.status = 'deleting';
  },
  [NEWS_DELETE_SUCCESS]: state => {
    state.status = 'deleted';
  },
  [NEWS_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
