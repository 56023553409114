<template>
  <BaseLayout :title="title">
    <template #actions>
      <InputSearch
        v-model="search"
        :placeholder="$t('placeholder.search')"
        @input="onSearch"
        @clear="onClearSearch"
        class="mb-0"
      />
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <NewsTable
      :list="newsList"
      :meta="newsListMeta"
      :current-page="currentPage"
      :is-loading="isLoading"
      @edit="onEdit"
      @remove="onConfirmDelete"
      @onChangePage="onChangePage"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { NEWS_LIST_REQUEST, NEWS_DELETE } from '@/store/actions/news';
import { KEY_FILTER_NAME } from '@/utils/filter';
import BaseLayout from '@/components/ui/BaseLayout';
import InputSearch from '@/components/ui/input/InputSearch';
import InlineButton from '@/components/ui/button/InlineButton';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import NewsTable from '@/components/manage/news/NewsTable';
import { DEBOUNCE_MILLISECONDS } from '@/config';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      dataIdToRemove: null,
      deleteModalId: 'manage-delete-modal',
      search: '',
      timeout: null,
    };
  },

  computed: {
    ...mapGetters(['newsStatus', 'newsList', 'newsListMeta', 'newsListStatus', 'news']),
    isLoading() {
      return this.newsListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(search) {
      const payload = {
        limit: 15,
        page: search ? 1 : this.currentPage,
        filter: {},
      };
      if (this.search) {
        payload.filter.q = this.search;
      }
      await this.$store.dispatch(NEWS_LIST_REQUEST, payload);
    },
    onClearSearch() {
      this.search = '';
      this.fetchData();
    },
    onSearch() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchData(true);
      }, DEBOUNCE_MILLISECONDS);
    },
    onCreate() {
      this.$router.push({ name: 'NewsAdd' });
    },
    onEdit(id) {
      this.$router.push({ name: 'NewsEdit', params: { id } });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(NEWS_DELETE, this.dataIdToRemove);
      if (this.newsStatus === 'deleted') {
        this.dataIdToRemove = null;
        this.fetchData();
      }
    },
  },

  components: {
    BaseLayout,
    InputSearch,
    InlineButton,
    NewsTable,
    ConfirmDialog,
  },
};
</script>
