export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_REQUEST_SUCCESS = 'CATEGORY_LIST_REQUEST_SUCCESS';
export const CATEGORY_LIST_REQUEST_ERROR = 'CATEGORY_LIST_REQUEST_ERROR';

export const CATEGORY_CREATE = 'CATEGORY_CREATE';
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS';
export const CATEGORY_CREATE_ERROR = 'CATEGORY_CREATE_ERROR';

export const CATEGORY_UPDATE = 'CATEGORY_UPDATE';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_ERROR = 'CATEGORY_UPDATE_ERROR';

export const CATEGORY_DELETE = 'CATEGORY_DELETE';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_ERROR = 'CATEGORY_DELETE_ERROR';
