import { find } from 'lodash';
import {
  PLAN_LIST_REQUEST,
  PLAN_LIST_REQUEST_ERROR,
  PLAN_LIST_REQUEST_SUCCESS,
  PLAN_CREATE,
  PLAN_CREATE_SUCCESS,
  PLAN_CREATE_ERROR,
  PLAN_UPDATE,
  PLAN_UPDATE_SUCCESS,
  PLAN_UPDATE_ERROR,
  PLAN_DELETE,
  PLAN_DELETE_SUCCESS,
  PLAN_DELETE_ERROR,
} from '@/store/actions/plan';
import { handleRequestError } from '@/utils/error';
import Repository, { PLANS } from '@/repositories/RepositoryFactory';

const PlanRepository = Repository.get(PLANS);

const state = {
  status: '',
  plan: {},
  planList: [],
  planListMeta: {},
  planListStatus: '',
};

const getters = {
  planStatus: state => state.status,
  planList: state => state.planList,
  planListMeta: state => state.planListMeta,
  planListStatus: state => state.planListStatus,
  getPlanById: (state, getters) => id => {
    if (getters.planList.length > 0) {
      return find(getters.planList, { id });
    }
    return state.plan;
  },
};

const actions = {
  [PLAN_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(PLAN_LIST_REQUEST);
    try {
      const response = await PlanRepository.get(payload);
      commit(PLAN_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(PLAN_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [PLAN_CREATE]: async ({ commit }, payload) => {
    commit(PLAN_CREATE);
    try {
      await PlanRepository.create(payload);
      commit(PLAN_CREATE_SUCCESS);
    } catch (error) {
      commit(PLAN_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [PLAN_UPDATE]: async ({ commit }, { id, data }) => {
    commit(PLAN_UPDATE);
    try {
      await PlanRepository.update(id, data);
      commit(PLAN_UPDATE_SUCCESS);
    } catch (error) {
      commit(PLAN_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [PLAN_DELETE]: async ({ commit }, id) => {
    commit(PLAN_DELETE);
    try {
      await PlanRepository.delete(id);
      commit(PLAN_DELETE_SUCCESS);
    } catch (error) {
      commit(PLAN_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [PLAN_LIST_REQUEST]: state => {
    state.planListStatus = 'loading';
  },
  [PLAN_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.planListStatus = 'success';
    state.planList = payload.data;
    if (payload.meta) {
      state.planListMeta = payload.meta;
    }
  },
  [PLAN_LIST_REQUEST_ERROR]: state => {
    state.planListStatus = 'error';
  },
  [PLAN_CREATE]: state => {
    state.status = 'loading';
  },
  [PLAN_CREATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [PLAN_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [PLAN_UPDATE]: state => {
    state.status = 'loading';
  },
  [PLAN_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [PLAN_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [PLAN_DELETE]: state => {
    state.status = 'deleting';
  },
  [PLAN_DELETE_SUCCESS]: state => {
    state.status = 'deleted';
  },
  [PLAN_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
