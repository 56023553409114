import { find } from 'lodash';
import {
  SOCIAL_LIST_REQUEST,
  SOCIAL_LIST_REQUEST_ERROR,
  SOCIAL_LIST_REQUEST_SUCCESS,
  SOCIAL_CREATE,
  SOCIAL_CREATE_SUCCESS,
  SOCIAL_CREATE_ERROR,
  SOCIAL_UPDATE,
  SOCIAL_UPDATE_SUCCESS,
  SOCIAL_UPDATE_ERROR,
  SOCIAL_DELETE,
  SOCIAL_DELETE_SUCCESS,
  SOCIAL_DELETE_ERROR,
} from '@/store/actions/social';
import { handleRequestError } from '@/utils/error';
import Repository, { SOCIALS } from '@/repositories/RepositoryFactory';

const SocialsRepository = Repository.get(SOCIALS);

const state = {
  status: '',
  social: {},
  socialList: [],
  socialListMeta: {},
  socialListStatus: '',
};

const getters = {
  socialStatus: state => state.status,
  socialList: state => state.socialList,
  socialListMeta: state => state.socialListMeta,
  socialListStatus: state => state.socialListStatus,
  getSocialById: (state, getters) => id => {
    if (getters.socialList.length > 0) {
      return find(getters.socialList, { id });
    }
    return state.social;
  },
};

const actions = {
  [SOCIAL_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(SOCIAL_LIST_REQUEST);
    try {
      const response = await SocialsRepository.get(payload);
      commit(SOCIAL_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SOCIAL_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [SOCIAL_CREATE]: async ({ commit }, payload) => {
    commit(SOCIAL_CREATE);
    try {
      await SocialsRepository.create(payload);
      commit(SOCIAL_CREATE_SUCCESS);
    } catch (error) {
      commit(SOCIAL_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [SOCIAL_UPDATE]: async ({ commit }, { id, data }) => {
    commit(SOCIAL_UPDATE);
    try {
      await SocialsRepository.update(id, data);
      commit(SOCIAL_UPDATE_SUCCESS);
    } catch (error) {
      commit(SOCIAL_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [SOCIAL_DELETE]: async ({ commit }, id) => {
    commit(SOCIAL_DELETE);
    try {
      await SocialsRepository.delete(id);
      commit(SOCIAL_DELETE_SUCCESS);
    } catch (error) {
      commit(SOCIAL_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SOCIAL_LIST_REQUEST]: state => {
    state.socialListStatus = 'loading';
  },
  [SOCIAL_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.socialListStatus = 'success';
    state.socialList = payload.data;
    if (payload.meta) {
      state.socialListMeta = payload.meta;
    }
  },
  [SOCIAL_LIST_REQUEST_ERROR]: state => {
    state.socialListStatus = 'error';
  },
  [SOCIAL_CREATE]: state => {
    state.status = 'loading';
  },
  [SOCIAL_CREATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [SOCIAL_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [SOCIAL_UPDATE]: state => {
    state.status = 'loading';
  },
  [SOCIAL_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [SOCIAL_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [SOCIAL_DELETE]: state => {
    state.status = 'deleting';
  },
  [SOCIAL_DELETE_SUCCESS]: state => {
    state.status = 'deleted';
  },
  [SOCIAL_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
