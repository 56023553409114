import {
  SEND_PASSWORD_RESET_EMAIL_REQUEST,
  SEND_PASSWORD_RESET_EMAIL_REQUEST_SUCCESS,
  SEND_PASSWORD_RESET_EMAIL_REQUEST_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_ERROR,
} from '@/store/actions/resetPassword';
import { handleRequestError } from '@/utils/error';
import Repository, { USERS } from '@/repositories/RepositoryFactory';

const UserRepository = Repository.get(USERS);

const state = {
  status: '',
  resetStatus: '',
};

const getters = {
  sentEmailStatus: state => state.status,
  resetStatus: state => state.resetStatus,
};

const actions = {
  [SEND_PASSWORD_RESET_EMAIL_REQUEST]: async ({ commit }, payload) => {
    commit(SEND_PASSWORD_RESET_EMAIL_REQUEST);
    try {
      commit(
        SEND_PASSWORD_RESET_EMAIL_REQUEST_SUCCESS,
        await UserRepository.sendPasswordResetEmail(payload)
      );
    } catch (error) {
      commit(SEND_PASSWORD_RESET_EMAIL_REQUEST_ERROR, error);

      handleRequestError(error);
    }
  },
  [RESET_PASSWORD_REQUEST]: async ({ commit }, payload) => {
    commit(RESET_PASSWORD_REQUEST);
    try {
      commit(RESET_PASSWORD_REQUEST_SUCCESS, await UserRepository.resetPassword(payload));
    } catch (error) {
      commit(RESET_PASSWORD_REQUEST_ERROR, error);

      handleRequestError(error);
    }
  },
};

const mutations = {
  [SEND_PASSWORD_RESET_EMAIL_REQUEST]: state => {
    state.status = 'loading';
  },
  [SEND_PASSWORD_RESET_EMAIL_REQUEST_SUCCESS]: state => {
    state.status = 'success';
  },
  [SEND_PASSWORD_RESET_EMAIL_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [RESET_PASSWORD_REQUEST]: state => {
    state.resetStatus = 'loading';
  },
  [RESET_PASSWORD_REQUEST_SUCCESS]: state => {
    state.resetStatus = 'success';
  },
  [RESET_PASSWORD_REQUEST_ERROR]: state => {
    state.resetStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
