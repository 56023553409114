<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.editButton') }}</span>
      </InlineButton>
    </template>

    <ContributeUpdateModal :modal-id="editModalId" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import ContributeUpdateModal from '@/components/manage/contributions/ContributeUpdateModal';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      editModalId: 'manage-form-modal',
    };
  },

  computed: {
    ...mapGetters([]),
  },

  methods: {
    onCreate() {
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    ContributeUpdateModal,
  },
};
</script>
