export const PAGE_LIST_REQUEST = 'PAGE_LIST_REQUEST';
export const PAGE_LIST_REQUEST_SUCCESS = 'PAGE_LIST_REQUEST_SUCCESS';
export const PAGE_LIST_REQUEST_ERROR = 'PAGE_LIST_REQUEST_ERROR';

export const PAGE_CREATE = 'PAGE_CREATE';
export const PAGE_CREATE_SUCCESS = 'PAGE_CREATE_SUCCESS';
export const PAGE_CREATE_ERROR = 'PAGE_CREATE_ERROR';

export const PAGE_UPDATE = 'PAGE_UPDATE';
export const PAGE_UPDATE_SUCCESS = 'PAGE_UPDATE_SUCCESS';
export const PAGE_UPDATE_ERROR = 'PAGE_UPDATE_ERROR';

export const PAGE_DELETE = 'PAGE_DELETE';
export const PAGE_DELETE_SUCCESS = 'PAGE_DELETE_SUCCESS';
export const PAGE_DELETE_ERROR = 'PAGE_DELETE_ERROR';
