export const CONTENT_LIST_REQUEST = 'CONTENT_LIST_REQUEST';
export const CONTENT_LIST_REQUEST_SUCCESS = 'CONTENT_LIST_REQUEST_SUCCESS';
export const CONTENT_LIST_REQUEST_ERROR = 'CONTENT_LIST_REQUEST_ERROR';

export const CONTENT_CREATE = 'CONTENT_CREATE';
export const CONTENT_CREATE_SUCCESS = 'CONTENT_CREATE_SUCCESS';
export const CONTENT_CREATE_ERROR = 'CONTENT_CREATE_ERROR';

export const CONTENT_UPDATE = 'CONTENT_UPDATE';
export const CONTENT_UPDATE_SUCCESS = 'CONTENT_UPDATE_SUCCESS';
export const CONTENT_UPDATE_ERROR = 'CONTENT_UPDATE_ERROR';

export const CONTENT_DELETE = 'CONTENT_DELETE';
export const CONTENT_DELETE_SUCCESS = 'CONTENT_DELETE_SUCCESS';
export const CONTENT_DELETE_ERROR = 'CONTENT_DELETE_ERROR';
