<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="type"
        :label="$t('label.type')"
        size="lg"
        v-model="$v.form.type.$model"
        :vuelidate="$v.form.type"
      />
      <InputString
        class="link"
        :label="$t('label.link')"
        size="lg"
        v-model="$v.form.link.$model"
        :vuelidate="$v.form.link"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { SOCIAL_CREATE, SOCIAL_UPDATE } from '@/store/actions/social';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';

const defaultValuesForForm = () => ({
  type: '',
  link: '',
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations: {
    form: {
      type: {
        required,
      },
      link: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['socialStatus']),
    isLoading() {
      return this.socialStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? this.$t('social.newSocial') : this.$t('social.editSocial');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            type: this.formData.type,
            link: this.formData.link,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(SOCIAL_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(SOCIAL_UPDATE, payload);
      }
      if (this.socialStatus === 'success') {
        this.hideEditModal();
      }
    },
  },

  components: {
    ModalMain,
    InputString,
  },
};
</script>
