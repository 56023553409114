import { find } from 'lodash';
import {
  PAGE_LIST_REQUEST,
  PAGE_LIST_REQUEST_ERROR,
  PAGE_LIST_REQUEST_SUCCESS,
  PAGE_CREATE,
  PAGE_CREATE_SUCCESS,
  PAGE_CREATE_ERROR,
  PAGE_UPDATE,
  PAGE_UPDATE_SUCCESS,
  PAGE_UPDATE_ERROR,
  PAGE_DELETE,
  PAGE_DELETE_SUCCESS,
  PAGE_DELETE_ERROR,
} from '@/store/actions/page';
import { handleRequestError } from '@/utils/error';
import Repository, { PAGES } from '@/repositories/RepositoryFactory';

const PagesRepository = Repository.get(PAGES);

const state = {
  status: '',
  page: {},
  pageList: [],
  pageListMeta: {},
  pageListStatus: '',
};

const getters = {
  pageStatus: state => state.status,
  pageList: state => state.pageList,
  pageListMeta: state => state.pageListMeta,
  pageListStatus: state => state.pageListStatus,
  getPageById: (state, getters) => id => {
    if (getters.pageList.length > 0) {
      return find(getters.pageList, { id });
    }
    return state.page;
  },
};

const actions = {
  [PAGE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(PAGE_LIST_REQUEST);
    try {
      const response = await PagesRepository.get(payload);
      commit(PAGE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(PAGE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [PAGE_CREATE]: async ({ commit }, payload) => {
    commit(PAGE_CREATE);
    try {
      await PagesRepository.create(payload);
      commit(PAGE_CREATE_SUCCESS);
    } catch (error) {
      commit(PAGE_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [PAGE_UPDATE]: async ({ commit }, { id, data }) => {
    commit(PAGE_UPDATE);
    try {
      await PagesRepository.update(id, data);
      commit(PAGE_UPDATE_SUCCESS);
    } catch (error) {
      commit(PAGE_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [PAGE_DELETE]: async ({ commit }, id) => {
    commit(PAGE_DELETE);
    try {
      await PagesRepository.delete(id);
      commit(PAGE_DELETE_SUCCESS);
    } catch (error) {
      commit(PAGE_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [PAGE_LIST_REQUEST]: state => {
    state.pageListStatus = 'loading';
  },
  [PAGE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.pageListStatus = 'success';
    state.pageList = payload.data;
    if (payload.meta) {
      state.pageListMeta = payload.meta;
    }
  },
  [PAGE_LIST_REQUEST_ERROR]: state => {
    state.pageListStatus = 'error';
  },
  [PAGE_CREATE]: state => {
    state.status = 'loading';
  },
  [PAGE_CREATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [PAGE_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [PAGE_UPDATE]: state => {
    state.status = 'loading';
  },
  [PAGE_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [PAGE_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [PAGE_DELETE]: state => {
    state.status = 'deleting';
  },
  [PAGE_DELETE_SUCCESS]: state => {
    state.status = 'deleted';
  },
  [PAGE_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
