<template>
  <div class="select-autocomplete-container">
    <InputLabel v-if="label">{{ label }}</InputLabel>
    <v-select
      :options="$_options"
      :label="textField"
      :value="value"
      :reduce="option => option.id"
      :loading="loading"
      :class="[
        'select-autocomplete',
        { 'is-invalid': $_hasError },
        { 'is-valid': $_state === true },
      ]"
      @input="onInput"
      @search="(search, loading) => onSearch(search, loading)"
    ></v-select>
  </div>
</template>

<script>
import { isNil } from 'lodash';
import baseInputMixin from '@/mixins/baseInputMixin';

export default {
  mixins: [baseInputMixin],

  props: {
    options: Array,
    firstItemLabel: String,
    firstItemDisabled: {
      type: Boolean,
      default: true,
    },
    valueField: {
      type: String,
      default: 'id',
    },
    textField: {
      type: String,
      default: 'name',
    },
    disabledField: {
      type: String,
      default: 'disabled',
    },
    pill: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    $_options() {
      const options = this.options.map(option => option);

      if (!isNil(this.firstItemLabel)) {
        options.unshift({
          [this.valueField]: null,
          [this.textField]: this.firstItemLabel,
          [this.disabledField]: this.firstItemDisabled,
        });
      }
      return options;
    },
  },

  methods: {
    async onSearch(search) {
      this.$emit('search', search);
    },
  },
};
</script>

<style lang="scss">
@import '../../../sass/variables';

.select-autocomplete-container {
  margin-bottom: 1rem;
}

.select-autocomplete {
  .vs__dropdown-toggle,
  .vs__dropdown-menu {
    border-width: 2px;
    border-color: $border-color;
  }

  &.is-invalid {
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      border-color: $danger;
    }
  }

  &.is-valid {
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      border-color: $success;
    }
  }
}
</style>
