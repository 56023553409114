import { find } from 'lodash';
import {
  STAFF_LIST_REQUEST,
  STAFF_LIST_REQUEST_ERROR,
  STAFF_LIST_REQUEST_SUCCESS,
  STAFF_CREATE,
  STAFF_CREATE_SUCCESS,
  STAFF_CREATE_ERROR,
  STAFF_UPDATE,
  STAFF_UPDATE_SUCCESS,
  STAFF_UPDATE_ERROR,
  STAFF_DELETE,
  STAFF_DELETE_SUCCESS,
  STAFF_DELETE_ERROR,
} from '@/store/actions/staff';
import { handleRequestError } from '@/utils/error';
import Repository, { STAFF } from '@/repositories/RepositoryFactory';

const StaffRepository = Repository.get(STAFF);

const state = {
  status: '',
  staff: {},
  staffList: [],
  staffListMeta: {},
  staffListStatus: '',
};

const getters = {
  staffStatus: state => state.status,
  staffList: state => state.staffList,
  staffListMeta: state => state.staffListMeta,
  staffListStatus: state => state.staffListStatus,
  getStaffById: (state, getters) => id => {
    if (getters.staffList.length > 0) {
      return find(getters.staffList, { id });
    }
    return state.staff;
  },
};

const actions = {
  [STAFF_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(STAFF_LIST_REQUEST);
    try {
      const response = await StaffRepository.get(payload);
      commit(STAFF_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(STAFF_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [STAFF_CREATE]: async ({ commit }, payload) => {
    commit(STAFF_CREATE);
    try {
      await StaffRepository.create(payload);
      commit(STAFF_CREATE_SUCCESS);
    } catch (error) {
      commit(STAFF_CREATE_ERROR);
      handleRequestError(error);
    }
  },

  [STAFF_UPDATE]: async ({ commit }, { id, data }) => {
    commit(STAFF_UPDATE);
    try {
      await StaffRepository.update(id, data);
      commit(STAFF_UPDATE_SUCCESS);
    } catch (error) {
      commit(STAFF_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [STAFF_DELETE]: async ({ commit }, id) => {
    commit(STAFF_DELETE);
    try {
      await StaffRepository.delete(id);
      commit(STAFF_DELETE_SUCCESS);
    } catch (error) {
      commit(STAFF_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [STAFF_LIST_REQUEST]: state => {
    state.staffListStatus = 'loading';
  },
  [STAFF_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.staffListStatus = 'success';
    state.staffList = payload.data;
    if (payload.meta) {
      state.staffListMeta = payload.meta;
    }
  },
  [STAFF_LIST_REQUEST_ERROR]: state => {
    state.staffListStatus = 'error';
  },
  [STAFF_CREATE]: state => {
    state.status = 'loading';
  },
  [STAFF_CREATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [STAFF_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [STAFF_UPDATE]: state => {
    state.status = 'loading';
  },
  [STAFF_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [STAFF_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [STAFF_DELETE]: state => {
    state.status = 'deleting';
  },
  [STAFF_DELETE_SUCCESS]: state => {
    state.status = 'deleted';
  },
  [STAFF_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
