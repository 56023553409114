<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="customer-name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />
      <InputString
        class="customer-email"
        :label="$t('label.email')"
        size="lg"
        v-model="$v.form.email.$model"
        :vuelidate="$v.form.email"
      />
      <!-- <InputDatepicker
        class="customer-ends-at"
        :label="$t('label.endsAt')"
        size="lg"
        v-model="$v.form.endsAt.$model"
        :vuelidate="$v.form.endsAt"
      /> -->
      <InputPassword
        class="customer-password"
        :label="$t('label.password')"
        size="lg"
        v-model="$v.form.password.$model"
        :vuelidate="$v.form.password"
        :invalidFeedback="$t('validation.passwordInvalidFeedback')"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { email, required, helpers } from 'vuelidate/lib/validators';
import { CUSTOMER_UPDATE } from '@/store/actions/customer';
import passwordRegExp from '@/utils/password';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
// import InputDatepicker from '@/components/ui/input/InputDatepicker';
import InputPassword from '@/components/ui/input/InputPassword';

const defaultValuesForForm = () => ({
  name: '',
  email: '',
  password: '',
  // endsAt: '',
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations() {
    if (isNil(this.formData)) {
      return {
        form: {
          name: {
            required,
          },
          email: {
            required,
            email,
          },
          // endsAt: {
          //   required,
          // },
          password: {
            required,
            password: helpers.regex('password', passwordRegExp),
          },
        },
      };
    }
    return {
      form: {
        name: {
          required,
        },
        email: {
          required,
          email,
        },
        // endsAt: {
        //   required,
        // },
        password: {
          password: helpers.regex('password', passwordRegExp),
        },
      },
    };
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['customerStatus', 'getFileUpload']),
    isLoading() {
      return this.customerStatus === 'loading';
    },
    title() {
      return isNil(this.formData)
        ? this.$t('customers.newCustomer')
        : this.$t('customers.editCustomer');
    },
    saveButtonTitle() {
      return isNil(this.formData)
        ? this.$t('customers.addCustomer')
        : this.$t('customers.updateCustomer');
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            email: this.formData.email,
            // endsAt: this.formData.endsAt,
            password: '',
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = { ...this.form, isAdmin: true };

      if (isNil(this.formData)) {
        // await this.$store.dispatch(CUSTOMER_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data: {
            ...data,
            password: this.form.password || null,
          },
        };
        await this.$store.dispatch(CUSTOMER_UPDATE, payload);
      }
      if (this.customerStatus === 'success') {
        this.hideEditModal();
      }
    },
  },

  components: {
    ModalMain,
    InputString,
    // InputDatepicker,
    InputPassword,
  },
};
</script>
