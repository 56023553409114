<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />
      <!-- <InputText
        class="description"
        :label="$t('label.description')"
        size="lg"
        v-model="form.description"
      /> -->
      <b-row class="mb-3">
        <b-col cols="6" sm="4">
          <InputLabel>{{ $t('label.image') }}</InputLabel>
          <InputPhoto
            :is-loading="isUploadingPhoto && !uploadingAnswerPhoto"
            :photo="form.file"
            :vuelidate="$v.form.file"
            @change="onChangePhoto"
            @remove="onDeletePhoto"
          />
        </b-col>
      </b-row>
      <InputRadioButton
        class="status"
        :label="$t('label.status')"
        size="lg"
        v-model="form.isActive"
        :options="statusOptions"
      />
      <div
        v-for="(answer, answerIndex) in $v.form.answers.$each.$iter"
        :key="`answer-${answerIndex}`"
        class="mb-4"
      >
        <hr class="my-4" />
        <InputLabel
          class="mb-3"
          style="font-size: 1.25rem; color: black; text-transform: capitalize"
        >
          {{ `${$t('label.answer')} ${parseInt(answerIndex, 10) + 1}` }}
        </InputLabel>
        <InputString class="answer mb-2" v-model="answer.name.$model" :vuelidate="answer.name" />
        <b-row class="mb-3">
          <b-col cols="6" sm="4">
            <InputLabel>{{ $t('label.image') }}</InputLabel>
            <InputPhoto
              :is-loading="uploadingAnswerPhoto && uploadingAnswerPhotoIndex === answerIndex"
              :photo="form.answers[answerIndex].file"
              :vuelidate="answer.file"
              @change="onChangeAnswerPhoto($event, answerIndex)"
              @remove="onDeleteAnswerPhoto(answerIndex)"
            />
          </b-col>
        </b-row>
        <template v-if="form.answers.length > 1">
          <InlineButton @click="removeAnswer(answerIndex)" variant="outline-danger">
            {{ $t('text.deleteButton') }}
          </InlineButton>
        </template>
      </div>
      <hr class="my-4" />
      <div class="text-right">
        <InlineButton @click="pushAnswer" variant="dark">{{ $t('text.addAnswer') }}</InlineButton>
      </div>
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { POLL_CREATE, POLL_UPDATE } from '@/store/actions/poll';
import { FILE_UPLOAD } from '@/store/actions/file';
import { STATUS_OPTIONS } from '@/utils/constants';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputLabel from '@/components/ui/input/InputLabel';
import InputString from '@/components/ui/input/InputString';
// import InputText from '@/components/ui/input/InputText';
import InputRadioButton from '@/components/ui/input/InputRadioButton';
import InlineButton from '@/components/ui/button/InlineButton';

const defaultValuesForForm = () => ({
  name: '',
  file: null,
  description: '',
  isActive: false,
  answers: [
    {
      name: '',
      file: null,
    },
  ],
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
      statusOptions: STATUS_OPTIONS,
      uploadingAnswerPhoto: false,
      uploadingAnswerPhotoIndex: null,
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
      file: {
        required,
      },
      answers: {
        $each: {
          required,
          name: {
            required,
          },
          file: {
            required,
          },
        },
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['pollStatus', 'getFileUpload', 'fileDeleteStatus']),
    isLoading() {
      return this.pollStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? this.$t('poll.newPoll') : this.$t('poll.editPoll');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            file: this.formData.file,
            description: this.formData.description,
            isActive: this.formData.isActive,
            answers: this.formData.answers.map(({ name, file }) => {
              return {
                name,
                file,
              };
            }),
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    pushAnswer() {
      this.form.answers.push({ name: '', file: null });
    },
    removeAnswer(index) {
      this.form.answers.splice(index, 1);
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(POLL_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(POLL_UPDATE, payload);
      }
      if (this.pollStatus === 'success') {
        this.hideEditModal();
      }
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.file = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    async onDeletePhoto() {
      this.form.file = null;
    },
    async onChangeAnswerPhoto(e, index) {
      this.uploadingAnswerPhoto = true;
      this.uploadingAnswerPhotoIndex = index;
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.answers[index].file = this.getFileUpload.item ? this.getFileUpload.item : null;
        this.uploadingAnswerPhoto = false;
        this.uploadingAnswerPhotoIndex = null;
      }
    },
    async onDeleteAnswerPhoto(index) {
      this.form.answers[index].file = null;
    },
  },

  components: {
    ModalMain,
    InputPhoto,
    InputLabel,
    InputString,
    // InputText,
    InputRadioButton,
    InlineButton,
  },
};
</script>
