import i18n from '@/i18n';

export const STAFF_TYPES = [
  { value: 'SUPER_ADMIN', name: i18n.t('staff.superAdmin') },
  // { value: 'MANAGER', name: i18n.t('staff.manager') },
  { value: 'JOURNALIST', name: i18n.t('staff.journalist') },
];

export const STATUS_OPTIONS = [
  { name: i18n.t('text.active'), value: true },
  { name: i18n.t('text.inactive'), value: false },
];

export const YES_OR_NO_OPTIONS = [
  { name: i18n.t('text.yes'), value: true },
  { name: i18n.t('text.no'), value: false },
];
