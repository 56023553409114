<template>
  <div v-if="typeof value === 'boolean'">
    <b-badge v-if="value" pill variant="success" class="status">{{ $t('text.active') }}</b-badge>
    <b-badge v-else pill variant="secondary" class="status">{{ $t('text.inactive') }}</b-badge>
  </div>
  <div v-else>
    <b-badge pill :variant="getVariant(value)" class="status">{{
      $t(`status.${value.toLowerCase()}`)
    }}</b-badge>
  </div>
</template>

<script>
export default {
  props: {
    value: [Boolean, String],
  },

  data() {
    return {
      successOptions: ['PENDING', 'COMPLETED', 'DELIVERED', 'CANCEL'],
    };
  },

  methods: {
    getVariant(status) {
      if (this.successOptions.includes(status)) {
        if (status === 'PENDING') {
          return 'warning';
        }
        if (status === 'COMPLETED') {
          return 'success';
        }
        if (status === 'DELIVERED') {
          return 'info';
        }
        return 'danger';
      }
      return 'light';
    },
  },
};
</script>

<style lang="scss">
.status {
  line-height: 1.5;
  min-width: 5.625rem;
  text-transform: capitalize;
}
</style>
