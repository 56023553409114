<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <TypeTable
      :list="typeList"
      :meta="typeListMeta"
      :current-page="currentPage"
      :is-loading="isLoading"
      @edit="onEdit"
      @onChangePage="onChangePage"
    />

    <TypeAddEditModal
      :form-data="dataToEdit"
      :modal-id="editModalId"
      @onHidden="onHidden"
      @onRemove="onConfirmDelete"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { TYPE_LIST_REQUEST, TYPE_DELETE } from '@/store/actions/type';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import TypeTable from '@/components/manage/types/TypeTable';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import TypeAddEditModal from '@/components/manage/types/TypeAddEditModal';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      dataIdToEdit: null,
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
    };
  },

  computed: {
    ...mapGetters(['typeStatus', 'typeList', 'typeListMeta', 'typeListStatus', 'getTypeById']),
    dataToEdit() {
      return this.dataIdToEdit ? this.getTypeById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.typeListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const payload = {
        limit: 15,
        page: this.currentPage,
        filter: {},
      };
      await this.$store.dispatch(TYPE_LIST_REQUEST, payload);
    },
    emptyingDataToEdit() {
      this.dataIdToEdit = null;
    },
    onCreate() {
      this.emptyingDataToEdit();
      this.openModal();
    },
    onEdit(id) {
      this.dataIdToEdit = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(TYPE_DELETE, this.dataIdToRemove);
      if (this.typeStatus === 'deleted') {
        this.$bvModal.hide(this.editModalId);
        this.dataIdToRemove = null;
      }
    },
    async onHidden() {
      await this.emptyingDataToEdit();
      this.fetchData();
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    TypeTable,
    TypeAddEditModal,
    ConfirmDialog,
  },
};
</script>
