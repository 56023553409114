import { stringify } from 'query-string';
import Client from '@/clients/apiClient';

const resource = 'dashboard';

export default {
  get(payload) {
    const params = Object.assign(
      {
        page: payload.page,
        limit: payload.limit || 10,
        start: payload.start,
      },
      payload.filter
    );
    return Client.get(`${resource}?${stringify(params)}`);
  },
};
