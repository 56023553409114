export const STAFF_LIST_REQUEST = 'STAFF_LIST_REQUEST';
export const STAFF_LIST_REQUEST_SUCCESS = 'STAFF_LIST_REQUEST_SUCCESS';
export const STAFF_LIST_REQUEST_ERROR = 'STAFF_LIST_REQUEST_ERROR';

export const STAFF_CREATE = 'STAFF_CREATE';
export const STAFF_CREATE_SUCCESS = 'STAFF_CREATE_SUCCESS';
export const STAFF_CREATE_ERROR = 'STAFF_CREATE_ERROR';

export const STAFF_UPDATE = 'STAFF_UPDATE';
export const STAFF_UPDATE_SUCCESS = 'STAFF_UPDATE_SUCCESS';
export const STAFF_UPDATE_ERROR = 'STAFF_UPDATE_ERROR';

export const STAFF_DELETE = 'STAFF_DELETE';
export const STAFF_DELETE_SUCCESS = 'STAFF_DELETE_SUCCESS';
export const STAFF_DELETE_ERROR = 'STAFF_DELETE_ERROR';
