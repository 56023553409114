import { find } from 'lodash';
import {
  TYPE_LIST_REQUEST,
  TYPE_LIST_REQUEST_ERROR,
  TYPE_LIST_REQUEST_SUCCESS,
  TYPE_CREATE,
  TYPE_CREATE_SUCCESS,
  TYPE_CREATE_ERROR,
  TYPE_UPDATE,
  TYPE_UPDATE_SUCCESS,
  TYPE_UPDATE_ERROR,
  TYPE_DELETE,
  TYPE_DELETE_SUCCESS,
  TYPE_DELETE_ERROR,
} from '@/store/actions/type';
import { handleRequestError } from '@/utils/error';
import Repository, { TYPES } from '@/repositories/RepositoryFactory';

const TypesRepository = Repository.get(TYPES);

const state = {
  status: '',
  type: {},
  typeList: [],
  typeListMeta: {},
  typeListStatus: '',
};

const getters = {
  typeStatus: state => state.status,
  typeList: state => state.typeList,
  typeListMeta: state => state.typeListMeta,
  typeListStatus: state => state.typeListStatus,
  getTypeById: (state, getters) => id => {
    if (getters.typeList.length > 0) {
      return find(getters.typeList, { id });
    }
    return state.type;
  },
};

const actions = {
  [TYPE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(TYPE_LIST_REQUEST);
    try {
      const response = await TypesRepository.get(payload);
      commit(TYPE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TYPE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [TYPE_CREATE]: async ({ commit }, payload) => {
    commit(TYPE_CREATE);
    try {
      await TypesRepository.create(payload);
      commit(TYPE_CREATE_SUCCESS);
    } catch (error) {
      commit(TYPE_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [TYPE_UPDATE]: async ({ commit }, { id, data }) => {
    commit(TYPE_UPDATE);
    try {
      await TypesRepository.update(id, data);
      commit(TYPE_UPDATE_SUCCESS);
    } catch (error) {
      commit(TYPE_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [TYPE_DELETE]: async ({ commit }, id) => {
    commit(TYPE_DELETE);
    try {
      await TypesRepository.delete(id);
      commit(TYPE_DELETE_SUCCESS);
    } catch (error) {
      commit(TYPE_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [TYPE_LIST_REQUEST]: state => {
    state.typeListStatus = 'loading';
  },
  [TYPE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.typeListStatus = 'success';
    state.typeList = payload.data;
    if (payload.meta) {
      state.typeListMeta = payload.meta;
    }
  },
  [TYPE_LIST_REQUEST_ERROR]: state => {
    state.typeListStatus = 'error';
  },
  [TYPE_CREATE]: state => {
    state.status = 'loading';
  },
  [TYPE_CREATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [TYPE_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [TYPE_UPDATE]: state => {
    state.status = 'loading';
  },
  [TYPE_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [TYPE_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [TYPE_DELETE]: state => {
    state.status = 'deleting';
  },
  [TYPE_DELETE_SUCCESS]: state => {
    state.status = 'deleted';
  },
  [TYPE_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
