export const NEWS_REQUEST = 'NEWS_REQUEST';
export const NEWS_REQUEST_SUCCESS = 'NEWS_REQUEST_SUCCESS';
export const NEWS_REQUEST_ERROR = 'NEWS_REQUEST_ERROR';

export const NEWS_LIST_REQUEST = 'NEWS_LIST_REQUEST';
export const NEWS_LIST_REQUEST_SUCCESS = 'NEWS_LIST_REQUEST_SUCCESS';
export const NEWS_LIST_REQUEST_ERROR = 'NEWS_LIST_REQUEST_ERROR';

export const NEWS_CREATE = 'NEWS_CREATE';
export const NEWS_CREATE_SUCCESS = 'NEWS_CREATE_SUCCESS';
export const NEWS_CREATE_ERROR = 'NEWS_CREATE_ERROR';

export const NEWS_UPDATE = 'NEWS_UPDATE';
export const NEWS_UPDATE_SUCCESS = 'NEWS_UPDATE_SUCCESS';
export const NEWS_UPDATE_ERROR = 'NEWS_UPDATE_ERROR';

export const NEWS_DELETE = 'NEWS_DELETE';
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS';
export const NEWS_DELETE_ERROR = 'NEWS_DELETE_ERROR';
