import { find, findIndex } from 'lodash';
import {
  CONTENT_LIST_REQUEST,
  CONTENT_LIST_REQUEST_ERROR,
  CONTENT_LIST_REQUEST_SUCCESS,
  CONTENT_CREATE,
  CONTENT_CREATE_SUCCESS,
  CONTENT_CREATE_ERROR,
  CONTENT_UPDATE,
  CONTENT_UPDATE_SUCCESS,
  CONTENT_UPDATE_ERROR,
  CONTENT_DELETE,
  CONTENT_DELETE_SUCCESS,
  CONTENT_DELETE_ERROR,
} from '@/store/actions/content';
import { handleRequestError } from '@/utils/error';
import Repository, { CONTENT } from '@/repositories/RepositoryFactory';

const ContentRepository = Repository.get(CONTENT);

const state = {
  status: '',
  content: {},
  contentList: [],
  contentListMeta: {},
  contentListStatus: '',
};

const getters = {
  content: state => state.content,
  contentStatus: state => state.status,
  contentList: state => state.contentList,
  contentListMeta: state => state.contentListMeta,
  contentListStatus: state => state.contentListStatus,
  getContentById: (state, getters) => id => {
    if (getters.contentList.length > 0) {
      return find(getters.contentList, { id });
    }
    return state.content;
  },
};

const actions = {
  [CONTENT_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(CONTENT_LIST_REQUEST);
    try {
      const response = await ContentRepository.get(payload);
      commit(CONTENT_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CONTENT_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [CONTENT_CREATE]: async ({ commit }, payload) => {
    commit(CONTENT_CREATE);
    try {
      const response = await ContentRepository.create(payload);
      commit(CONTENT_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(CONTENT_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [CONTENT_UPDATE]: async ({ commit }, { id, data }) => {
    commit(CONTENT_UPDATE);
    try {
      const response = await ContentRepository.update(id, data);
      commit(CONTENT_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(CONTENT_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [CONTENT_DELETE]: async ({ commit }, id) => {
    commit(CONTENT_DELETE);
    try {
      await ContentRepository.delete(id);
      commit(CONTENT_DELETE_SUCCESS, id);
    } catch (error) {
      commit(CONTENT_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [CONTENT_LIST_REQUEST]: state => {
    state.contentListStatus = 'loading';
  },
  [CONTENT_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.contentListStatus = 'success';
    state.contentList = payload.data;
    if (payload.meta) {
      state.contentListMeta = payload.meta;
    }
  },
  [CONTENT_LIST_REQUEST_ERROR]: state => {
    state.contentListStatus = 'error';
  },
  [CONTENT_CREATE]: state => {
    state.status = 'loading';
  },
  [CONTENT_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.contentList.push(payload);
  },
  [CONTENT_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [CONTENT_UPDATE]: state => {
    state.status = 'loading';
  },
  [CONTENT_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.contentList = state.contentList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [CONTENT_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [CONTENT_DELETE]: state => {
    state.status = 'deleting';
  },
  [CONTENT_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.contentList, { id: payload });
    state.contentList.splice(index, 1);
  },
  [CONTENT_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
