<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="$router.push({ name: 'News' })" pill variant="outline-secondary">
        <span class="text-uppercase">
          {{ $t('text.backButton') }}
        </span>
      </InlineButton>
      <InlineButton @click="onSave" pill shadow :loading="isLoading">
        <span class="text-uppercase">
          {{ news && news.id ? $t('text.updateButton') : $t('text.newButton') }}
        </span>
      </InlineButton>
    </template>

    <SpinLoader v-if="fetchLoading" :size="1.5" />
    <section v-else class="section">
      <div class="pt-3">
        <b-row class="mb-3">
          <b-col cols="12" md="6">
            <b-form-file
              v-if="newsId"
              @change="onFileChange"
              accept=".jpg, .jpeg"
              :placeholder="$t('placeholder.imageBrowse')"
              :browse-text="$t('label.imageBrowse')"
            >
            </b-form-file>
            <b-form-file
              v-else
              :state="$v.file.$dirty ? !$v.file.$error : null"
              @change="onFileChange"
              accept=".jpg, .jpeg"
              :placeholder="$t('placeholder.imageBrowse')"
              :browse-text="$t('label.imageBrowse')"
            >
            </b-form-file>
            <InputString
              class="image-description mt-3"
              :label="$t('label.imageDescription')"
              v-model="form.imageDescription"
            />
          </b-col>
        </b-row>

        <b-row v-if="uploadedImage" class="spaced-row d-none d-md-flex">
          <b-col cols="12" md="6" xl="3">
            <vue-cropper
              ref="cropper"
              alt="Source Image"
              :src="uploadedImage"
              :guides="true"
              :view-mode="2"
              drag-mode="crop"
              :auto-crop-area="1"
              :min-container-width="250"
              :min-container-height="180"
              :background="true"
              :rotatable="true"
              :aspectRatio="4 / 3"
            >
            </vue-cropper>
          </b-col>
          <b-col cols="12" md="6" xl="3">
            <vue-cropper
              ref="cropper2"
              alt="Source Image"
              :src="uploadedImage"
              :guides="true"
              :view-mode="2"
              drag-mode="crop"
              :auto-crop-area="1"
              :min-container-width="250"
              :min-container-height="180"
              :background="true"
              :rotatable="true"
              :aspectRatio="5 / 2"
            >
            </vue-cropper>
          </b-col>
          <b-col cols="12" md="6" xl="3">
            <vue-cropper
              ref="cropper3"
              alt="Source Image"
              :src="uploadedImage"
              :guides="true"
              :view-mode="2"
              drag-mode="crop"
              :auto-crop-area="1"
              :min-container-width="250"
              :min-container-height="180"
              :background="true"
              :rotatable="true"
              :aspectRatio="1 / 1"
            >
            </vue-cropper>
          </b-col>
          <b-col cols="12" md="6" xl="3">
            <vue-cropper
              ref="cropper4"
              alt="Source Image"
              :src="uploadedImage"
              :guides="true"
              :view-mode="2"
              drag-mode="crop"
              :auto-crop-area="1"
              :min-container-width="250"
              :min-container-height="180"
              :background="true"
              :rotatable="true"
              :aspectRatio="40 / 21"
            >
            </vue-cropper>
          </b-col>
        </b-row>
      </div>

      <hr class="my-5" />

      <b-row>
        <b-col cols="12" md="8" xl="6">
          <InputString
            class="name"
            :label="$t('label.name')"
            v-model="$v.form.name.$model"
            :vuelidate="$v.form.name"
          />
        </b-col>
        <b-col cols="12" md="4" xl="3">
          <InputSelect
            class="category"
            :label="$t('label.category')"
            v-model="$v.form.categoryId.$model"
            :vuelidate="$v.form.categoryId"
            :options="categoryList"
            :disabled="isLoadingCategories"
          />
        </b-col>
        <b-col cols="6" md="4" xl="3">
          <InputSelect
            class="type"
            :label="$t('label.type')"
            v-model="$v.form.typeId.$model"
            :vuelidate="$v.form.typeId"
            :options="typeList"
            :disabled="isLoadingTypes"
          />
        </b-col>
        <b-col v-if="isAdmin" cols="6" md="4" xl="3">
          <InputSelect
            class="user"
            :label="$t('label.journalist')"
            v-model="$v.form.userId.$model"
            :vuelidate="$v.form.userId"
            :options="staffList"
            :disabled="isLoadingStaff"
          />
        </b-col>
        <b-col cols="12" md="4" xl="3">
          <InputRadioButton
            class="status"
            :label="$t('label.status')"
            v-model="form.isActive"
            :options="statusOptions"
          />
        </b-col>
        <b-col cols="12" md="4" xl="3">
          <InputRadioButton
            class="show-on-home"
            :label="$t('label.showOnHome')"
            v-model="form.isFeature"
            :options="yesOrNoOptions"
          />
        </b-col>
        <b-col cols="12" md="4" xl="3">
          <InputRadioButton
            class="is-trend"
            :label="$t('label.trendNews')"
            v-model="form.isTrend"
            :options="yesOrNoOptions"
          />
        </b-col>
        <b-col cols="12" md="4" xl="3">
          <InputRadioButton
            class="is-breaking"
            :label="$t('label.breakingNews')"
            v-model="form.isInstant"
            :options="yesOrNoOptions"
          />
        </b-col>
        <b-col cols="12" md="4" xl="3">
          <InputRadioButton
            class="is-live"
            :label="$t('label.liveNews')"
            v-model="form.isLive"
            :options="yesOrNoOptions"
          />
        </b-col>
        <b-col cols="12" md="4" xl="3">
          <InputRadioButton
            class="comment-disable"
            :label="$t('label.disableComment')"
            v-model="form.commentDisabled"
            :options="yesOrNoOptions"
          />
        </b-col>
        <b-col cols="12" md="4" xl="3">
          <InputDatepicker
            class="published-at"
            :label="$t('label.publishedAt')"
            enable-time
            v-model="$v.form.publishedAt.$model"
            :vuelidate="$v.form.publishedAt"
          />
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col cols="12" xxl="6">
          <div v-for="(item, itemIndex) in $v.form.items.$each.$iter" :key="`item-${itemIndex}`">
            <hr v-if="itemIndex > 0" style="border-style: dashed" class="my-4" />
            <InputSelect
              :class="`item-${itemIndex}`"
              :label="$t('label.type')"
              v-model="item.type.$model"
              :vuelidate="item.type"
              :options="itemTypes"
            />
            <div v-if="form.items[itemIndex].type === 'TEXT'">
              <InputEditor
                :class="`description-${itemIndex}`"
                :label="$t('label.description')"
                size="lg"
                is-large
                v-model="item.description.$model"
                :vuelidate="item.description"
                has-image
              />
            </div>
            <div v-if="form.items[itemIndex].type === 'POLL'">
              <InputSelectAutoComplete
                :class="`poll-${itemIndex}`"
                :label="$t('label.poll')"
                v-model="item.pollId.$model"
                :vuelidate="item.pollId"
                :options="pollList"
                :loading="isLoadingPollList"
                @search="onFilterPollList"
              />
            </div>
            <div v-if="form.items[itemIndex].type === 'GALLERY'">
              <InputSelectAutoComplete
                :class="`gallery-${itemIndex}`"
                :label="$t('label.gallery')"
                v-model="item.galleryId.$model"
                :vuelidate="item.galleryId"
                :options="galleryList"
                :loading="isLoadingGalleries"
                @search="onFilterGalleryList"
              />
            </div>
            <div v-if="form.items[itemIndex].type === 'EMBED'">
              <InputText
                :class="`embed-code-${itemIndex}`"
                :label="$t('label.embedCode')"
                size="lg"
                v-model="item.description.$model"
                :vuelidate="item.description"
              />
            </div>
            <div v-if="form.items && form.items.length > 1" class="text-right">
              <InlineButton @click="removeParagraph(itemIndex)" variant="outline-danger" size="sm">
                {{ $t('text.deleteButton') }}
              </InlineButton>
            </div>
          </div>
        </b-col>
        <b-col cols="12" xxl="6" class="d-none d-xxl-block">
          <InputLabel>{{ $t('label.previewNews') }}</InputLabel>
          <div
            v-for="(item, previewItemIndex) in form.items"
            :key="`preview-item-${previewItemIndex}`"
          >
            <template v-if="item.type === 'TEXT'">
              <div class="news-preview content" v-html="item.description"></div>
            </template>
            <div v-else class="news-preview widget">
              <template v-if="item.type === 'GALLERY'"> Зургийн цомог </template>
              <template v-else-if="item.type === 'POLL'"> Санал асуулга </template>
              <template v-else> Embed code </template>
            </div>
          </div>
        </b-col>
      </b-row>

      <hr />

      <div>
        <InlineButton @click="addParagraph">{{ $t('text.addParagraph') }}</InlineButton>
      </div>
      <br />
      <br />
    </section>
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import meMixin from '@/mixins/meMixin';
import { STATUS_OPTIONS, YES_OR_NO_OPTIONS } from '@/utils/constants';
import { NEWS_CREATE, NEWS_UPDATE, NEWS_REQUEST } from '@/store/actions/news';
import { CATEGORY_LIST_REQUEST } from '@/store/actions/category';
import { POLL_LIST_REQUEST } from '@/store/actions/poll';
import { TYPE_LIST_REQUEST } from '@/store/actions/type';
import { STAFF_LIST_REQUEST } from '@/store/actions/staff';
import { GALLERY_LIST_REQUEST } from '@/store/actions/gallery';
import { KEY_FILTER_NAME } from '@/utils/filter';
import humps from 'humps';
import SpinLoader from '@/components/ui/SpinLoader';
import BaseLayout from '@/components/ui/BaseLayout';
import InputSelect from '@/components/ui/input/InputSelect';
import InputSelectAutoComplete from '@/components/ui/input/InputSelectAutoComplete';
import InputString from '@/components/ui/input/InputString';
import InputRadioButton from '@/components/ui/input/InputRadioButton';
import InputDatepicker from '@/components/ui/input/InputDatepicker';
import InputEditor from '@/components/ui/input/InputEditor';
import InputLabel from '@/components/ui/input/InputLabel';
import InputText from '@/components/ui/input/InputText';
import InlineButton from '@/components/ui/button/InlineButton';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

const defaultValuesForItem = () => ({
  pollId: null,
  galleryId: null,
  description: '',
  type: 'TEXT',
});

const defaultValuesForForm = () => ({
  name: '',
  userId: null,
  categoryId: null,
  typeId: null,
  createdBy: null,
  isActive: false,
  isFeature: false,
  isTrend: false,
  isInstant: false,
  isCopy: false,
  isLive: false,
  commentDisabled: false,
  publishedAt: '',
  imageDescription: '',
  items: [defaultValuesForItem()],
});

export default {
  mixins: [validationMixin, meMixin],

  props: {
    title: String,
  },

  data() {
    return {
      newsId: this.$route.params.id ? parseInt(this.$route.params.id, 10) : null,
      form: defaultValuesForForm(),
      itemTypes: [
        {
          id: 'TEXT',
          name: this.$t('label.text'),
        },
        {
          id: 'POLL',
          name: this.$t('label.poll'),
        },
        {
          id: 'GALLERY',
          name: this.$t('label.gallery'),
        },
        {
          id: 'EMBED',
          name: this.$t('label.embedCode'),
        },
      ],
      statusOptions: STATUS_OPTIONS,
      yesOrNoOptions: YES_OR_NO_OPTIONS,
      uploadedImage: '',
      file: null,
      fetchLoading: true,
    };
  },

  validations() {
    if (this.newsId) {
      return {
        form: {
          name: {
            required,
          },
          categoryId: {
            required,
          },
          typeId: {
            required,
          },
          userId: {
            required,
          },
          publishedAt: {
            required,
          },
          items: {
            $each: {
              type: {
                required,
              },
              description: {
                required: requiredIf(values => {
                  return values.type === 'TEXT';
                }),
              },
              pollId: {
                required: requiredIf(values => {
                  return values.type === 'POLL';
                }),
              },
              galleryId: {
                required: requiredIf(values => {
                  return values.type === 'GALLERY';
                }),
              },
            },
          },
        },
      };
    }

    return {
      file: {
        required,
      },
      form: {
        name: {
          required,
        },
        categoryId: {
          required,
        },
        typeId: {
          required,
        },
        userId: {
          required,
        },
        publishedAt: {
          required,
        },
        items: {
          $each: {
            type: {
              required,
            },
            description: {
              required: requiredIf(values => {
                return values.type === 'TEXT' || values.type === 'EMBED';
              }),
            },
            pollId: {
              required: requiredIf(values => {
                return values.type === 'POLL';
              }),
            },
            galleryId: {
              required: requiredIf(values => {
                return values.type === 'GALLERY';
              }),
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      'getFileUpload',
      'newsStatus',
      'news',
      'categoryList',
      'categoryListStatus',
      'typeList',
      'typeListStatus',
      'staffList',
      'staffListStatus',
      'pollListStatus',
      'pollList',
      'galleryList',
      'galleryListStatus',
    ]),
    isLoading() {
      return this.newsStatus === 'loading';
    },
    isLoadingCategories() {
      return this.categoryListStatus === 'loading';
    },
    isLoadingPollList() {
      return this.pollListStatus === 'loading';
    },
    isLoadingTypes() {
      return this.typeListStatus === 'loading';
    },
    isLoadingStaff() {
      return this.staffListStatus === 'loading';
    },
    isLoadingGalleries() {
      return this.galleryListStatus === 'loading';
    },
    isJournalist() {
      return this.me.type && this.me.type === 'JOURNALIST';
    },
    isAdmin() {
      return this.me.type && this.me.type === 'SUPER_ADMIN';
    },
  },

  async created() {
    const payload = {
      limit: 150,
      page: this.currentPage,
      filter: {},
    };
    this.$store.dispatch(CATEGORY_LIST_REQUEST, payload);
    this.$store.dispatch(TYPE_LIST_REQUEST, payload);
    this.$store.dispatch(STAFF_LIST_REQUEST, payload);
    this.$store.dispatch(POLL_LIST_REQUEST, payload);
    this.$store.dispatch(GALLERY_LIST_REQUEST, payload);

    this.form.publishedAt = this.$moment().format('YYYY-MM-DD HH:mm');

    if (this.newsId) {
      await this.$store.dispatch(NEWS_REQUEST, this.newsId);
      if (this.newsStatus === 'success') {
        this.form = {
          name: this.news.name,
          userId: this.news.userId,
          categoryId: this.news.categoryId,
          typeId: this.news.typeId,
          createdBy: this.news.createdBy,
          isActive: this.news.isActive,
          isFeature: this.news.isFeature,
          isTrend: this.news.isTrend,
          isInstant: this.news.isInstant,
          isCopy: this.news.isCopy,
          isLive: this.news.isLive,
          commentDisabled: this.news.commentDisabled,
          publishedAt: this.news.publishedAt,
          imageDescription: this.news.imageDescription,
          items: this.news.items.map(item => {
            return {
              ...item,
            };
          }),
        };
      }
    } else if (this.isJournalist) {
      this.form.userId = this.getId(this.me);
    }

    this.fetchLoading = false;
  },

  methods: {
    onFileChange($event) {
      this.uploadedImage = null;
      const file = $event.target.files[0];
      this.file = file;
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.uploadedImage = reader.result;
      });
      reader.readAsDataURL(file);
    },
    async onFilterPollList(search) {
      const payload = {
        limit: 150,
        page: this.currentPage,
        filter: {},
      };
      payload.filter[`${KEY_FILTER_NAME}`] = search;
      await this.$store.dispatch(POLL_LIST_REQUEST, payload);
    },
    async onFilterGalleryList(search) {
      const payload = {
        limit: 150,
        page: this.currentPage,
        filter: {},
      };
      payload.filter[`${KEY_FILTER_NAME}`] = search;
      await this.$store.dispatch(GALLERY_LIST_REQUEST, payload);
    },
    addParagraph() {
      this.form.items.push(defaultValuesForItem());
    },
    removeParagraph(index) {
      this.form.items.splice(index, 1);
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.newsId === null) {
          this.$v.file.$touch();
        }

        if (this.$v.form.$anyError) {
          return;
        }

        if (this.newsId === null) {
          if (this.$v.file.$anyError) {
            return;
          }
        }
      }

      const formData = new FormData();
      if (this.file) {
        formData.append('primary_file', this.file);
      }
      if (this.uploadedImage) {
        formData.append(
          'image',
          this.dataURLToBlob(this.$refs.cropper.getCroppedCanvas({ format: 'jpg' }).toDataURL())
        );
        formData.append(
          'image1',
          this.dataURLToBlob(this.$refs.cropper2.getCroppedCanvas().toDataURL())
        );
        formData.append(
          'image2',
          this.dataURLToBlob(this.$refs.cropper3.getCroppedCanvas().toDataURL())
        );
        formData.append(
          'image3',
          this.dataURLToBlob(this.$refs.cropper4.getCroppedCanvas().toDataURL())
        );
      }

      formData.append('name', this.form.name);
      formData.append('user_id', this.form.userId);
      formData.append('category_id', this.form.categoryId);
      formData.append('type_id', this.form.typeId);
      formData.append('created_by', this.form.createdBy);
      formData.append('is_active', this.form.isActive ? '1' : '0');
      formData.append('is_feature', this.form.isFeature ? '1' : '0');
      formData.append('is_trend', this.form.isTrend ? '1' : '0');
      formData.append('is_instant', this.form.isInstant ? '1' : '0');
      formData.append('is_copy', this.form.isCopy ? '1' : '0');
      formData.append('is_live', this.form.isLive ? '1' : '0');
      formData.append('comment_disabled', this.form.commentDisabled ? '1' : '0');
      formData.append('published_at', this.form.publishedAt);
      formData.append('image_description', this.form.imageDescription);
      formData.append('items', JSON.stringify(humps.decamelizeKeys(this.form.items)));

      if (this.newsId) {
        const payload = {
          id: this.newsId,
          data: formData,
        };
        await this.$store.dispatch(NEWS_UPDATE, payload);
      } else {
        await this.$store.dispatch(NEWS_CREATE, formData);
      }
      if (this.newsStatus === 'success') {
        this.$router.push({ name: 'News' });
      }
    },
    dataURLToBlob(dataURL) {
      const BASE64_MARKER = ';base64,';
      if (dataURL.indexOf(BASE64_MARKER) === -1) {
        const parts = dataURL.split(',');
        const contentType = parts[0].split(':')[1];
        const raw = parts[1];

        return new Blob([raw], { type: contentType });
      }

      const parts = dataURL.split(BASE64_MARKER);
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;

      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },
  },

  components: {
    SpinLoader,
    BaseLayout,
    InputSelect,
    InputSelectAutoComplete,
    InputString,
    InputRadioButton,
    InputEditor,
    InputLabel,
    InputDatepicker,
    InputText,
    InlineButton,
    VueCropper,
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables';

.section {
  padding: 0 0.75rem;

  @media (min-width: $breakpoint-md) {
    padding: 0 1.5rem;
  }
}

.spaced-row {
  margin: -0.5rem;

  & > * {
    padding: 0.5rem;
  }
}
</style>
