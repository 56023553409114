export const GALLERY_REQUEST = 'GALLERY_REQUEST';
export const GALLERY_REQUEST_SUCCESS = 'GALLERY_REQUEST_SUCCESS';
export const GALLERY_REQUEST_ERROR = 'GALLERY_REQUEST_ERROR';

export const GALLERY_LIST_REQUEST = 'GALLERY_LIST_REQUEST';
export const GALLERY_LIST_REQUEST_SUCCESS = 'GALLERY_LIST_REQUEST_SUCCESS';
export const GALLERY_LIST_REQUEST_ERROR = 'GALLERY_LIST_REQUEST_ERROR';

export const GALLERY_CREATE = 'GALLERY_CREATE';
export const GALLERY_CREATE_SUCCESS = 'GALLERY_CREATE_SUCCESS';
export const GALLERY_CREATE_ERROR = 'GALLERY_CREATE_ERROR';

export const GALLERY_UPDATE = 'GALLERY_UPDATE';
export const GALLERY_UPDATE_SUCCESS = 'GALLERY_UPDATE_SUCCESS';
export const GALLERY_UPDATE_ERROR = 'GALLERY_UPDATE_ERROR';

export const GALLERY_DELETE = 'GALLERY_DELETE';
export const GALLERY_DELETE_SUCCESS = 'GALLERY_DELETE_SUCCESS';
export const GALLERY_DELETE_ERROR = 'GALLERY_DELETE_ERROR';
