import Client from '@/clients/apiClient';
// import ClientFormData from '@/clients/apiFormDataClient';
import { stringify } from 'query-string';
import { USER_TOKEN_KEY } from '@/config';

const resource = 'news';

export default {
  get(payload) {
    const params = Object.assign(
      {
        page: payload.page,
        limit: payload.limit || 10,
      },
      payload.filter
    );
    return Client.get(`${resource}?${stringify(params)}`);
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  create(payload) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${window.$cookies.get(USER_TOKEN_KEY)}`);
    return fetch(`${process.env.VUE_APP_API_URL}/${resource}`, {
      method: 'POST',
      body: payload,
      headers,
    });
    // return ClientFormData.post(`${resource}`, payload);
  },
  update(id, payload) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${window.$cookies.get(USER_TOKEN_KEY)}`);
    return fetch(`${process.env.VUE_APP_API_URL}/${resource}/${id}?_method=PUT`, {
      method: 'POST',
      body: payload,
      headers,
    });
    // return ClientFormData.post(`${resource}/${id}?_method=PUT`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
};
