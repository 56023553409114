<template>
  <div class="gallery-photos">
    <InputLabel>{{ $t('label.photo') }}</InputLabel>

    <b-row>
      <b-col cols="4" sm="4" md="4">
        <InputPhoto
          :is-loading="isLoading"
          :vuelidate="vuelidate"
          @change="$emit('change', $event)"
        />
      </b-col>
      <b-col
        v-for="(photo, photoIndex) in photos"
        :key="`photo-${photoIndex}`"
        cols="4"
        sm="4"
        md="4"
      >
        <InputPhoto :photo="photo" @remove="$emit('remove', photoIndex)" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputLabel from '@/components/ui/input/InputLabel';
import InputPhoto from '@/components/ui/input/InputPhoto';

export default {
  props: {
    photos: Array,
    isLoading: Boolean,
    vuelidate: Object,
  },

  components: {
    InputLabel,
    InputPhoto,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';

.gallery-photos {
  margin-bottom: 1rem;

  .row {
    margin: -7px;

    & > div {
      padding: 7px;
    }
  }
}
</style>
