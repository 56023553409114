<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <GalleryTable
      :list="galleryList"
      :meta="galleryListMeta"
      :current-page="currentPage"
      :is-loading="isLoading"
      @edit="onEdit"
      @onChangePage="onChangePage"
    />

    <GalleryAddEditModal
      :form-data="dataToEdit"
      :modal-id="editModalId"
      @onHidden="onHidden"
      @onRemove="onConfirmDelete"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { GALLERY_LIST_REQUEST, GALLERY_DELETE } from '@/store/actions/gallery';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import GalleryTable from '@/components/manage/galleries/GalleryTable';
import GalleryAddEditModal from '@/components/manage/galleries/GalleryAddEditModal';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      dataIdToEdit: null,
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
    };
  },

  computed: {
    ...mapGetters([
      'galleryStatus',
      'galleryList',
      'galleryListMeta',
      'galleryListStatus',
      'getGalleryById',
    ]),
    dataToEdit() {
      return this.dataIdToEdit ? this.getGalleryById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.galleryListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const payload = {
        limit: 15,
        page: this.currentPage,
        filter: {},
      };
      await this.$store.dispatch(GALLERY_LIST_REQUEST, payload);
    },
    emptyingDataToEdit() {
      this.dataIdToEdit = null;
    },
    onCreate() {
      this.emptyingDataToEdit();
      this.openModal();
    },
    onEdit(id) {
      this.dataIdToEdit = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(GALLERY_DELETE, this.dataIdToRemove);
      if (this.galleryStatus === 'deleted') {
        this.$bvModal.hide(this.editModalId);
        this.dataIdToRemove = null;
      }
    },
    onHidden() {
      this.emptyingDataToEdit();
      this.fetchData();
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    GalleryTable,
    GalleryAddEditModal,
    ConfirmDialog,
  },
};
</script>
