export const CONTRIBUTION_REQUEST = 'CONTRIBUTION_REQUEST';
export const CONTRIBUTION_REQUEST_SUCCESS = 'CONTRIBUTION_REQUEST_SUCCESS';
export const CONTRIBUTION_REQUEST_ERROR = 'CONTRIBUTION_REQUEST_ERROR';

export const CONTRIBUTION_LIST_REQUEST = 'CONTRIBUTION_LIST_REQUEST';
export const CONTRIBUTION_LIST_REQUEST_SUCCESS = 'CONTRIBUTION_LIST_REQUEST_SUCCESS';
export const CONTRIBUTION_LIST_REQUEST_ERROR = 'CONTRIBUTION_LIST_REQUEST_ERROR';

export const CONTRIBUTION_CREATE = 'CONTRIBUTION_CREATE';
export const CONTRIBUTION_CREATE_SUCCESS = 'CONTRIBUTION_CREATE_SUCCESS';
export const CONTRIBUTION_CREATE_ERROR = 'CONTRIBUTION_CREATE_ERROR';

export const CONTRIBUTION_UPDATE = 'CONTRIBUTION_UPDATE';
export const CONTRIBUTION_UPDATE_SUCCESS = 'CONTRIBUTION_UPDATE_SUCCESS';
export const CONTRIBUTION_UPDATE_ERROR = 'CONTRIBUTION_UPDATE_ERROR';

export const CONTRIBUTION_DELETE = 'CONTRIBUTION_DELETE';
export const CONTRIBUTION_DELETE_SUCCESS = 'CONTRIBUTION_DELETE_SUCCESS';
export const CONTRIBUTION_DELETE_ERROR = 'CONTRIBUTION_DELETE_ERROR';
