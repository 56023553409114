import Client from '@/clients/apiClient';

const resource = 'donate-texts';

export default {
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
};
