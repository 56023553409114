<template>
  <b-form-group
    :label="label"
    :label-class="labelClass"
    :label-for="`input-${uuid}`"
    :invalid-feedback="invalidFeedback"
    :valid-feedback="validFeedback"
    :state="$_state"
    :disabled="disabled"
  >
    <flat-pickr
      :value="value"
      :config="config"
      :placeholder="placeholder"
      :disabled="disabled"
      @on-change="onChange"
      @on-close="onClose"
      @on-open="onOpen"
      :class="[
        `form-control ${size ? `form-control-${size}` : ''}`,
        { 'is-invalid': $_state === false },
        { 'is-valid': $_state === true },
      ]"
    ></flat-pickr>
  </b-form-group>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';
import flatPickr from 'vue-flatpickr-component';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';

export default {
  mixins: [baseInputMixin],
  props: {
    min: [String, Date],
    max: [String, Date],
    enableTime: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    config() {
      const config = {
        mode: 'single', // 'single', 'multiple', 'range'
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: this.enableTime ? 'Y-m-d H:i' : 'Y-m-d',
        altInput: false,
        dateFormat: this.enableTime ? 'Y-m-d H:i' : 'Y-m-d',
        enableTime: this.enableTime,
        time_24hr: true,
        maxDate: this.max,
        minDate: this.min,
        plugins: [],
      };
      if (this.enableTime) {
        config.plugins.push(new ConfirmDatePlugin({}));
      }
      return config;
    },
  },
  methods: {
    onChange(selectedDates, dateStr) {
      this.$emit('input', dateStr);
    },
    onOpen() {
      this.$emit('open');
    },
    onClose() {
      this.$emit('close');
    },
  },
  components: {
    flatPickr,
  },
};
</script>
