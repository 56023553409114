export const PLAN_LIST_REQUEST = 'PLAN_LIST_REQUEST';
export const PLAN_LIST_REQUEST_SUCCESS = 'PLAN_LIST_REQUEST_SUCCESS';
export const PLAN_LIST_REQUEST_ERROR = 'PLAN_LIST_REQUEST_ERROR';

export const PLAN_CREATE = 'PLAN_CREATE';
export const PLAN_CREATE_SUCCESS = 'PLAN_CREATE_SUCCESS';
export const PLAN_CREATE_ERROR = 'PLAN_CREATE_ERROR';

export const PLAN_UPDATE = 'PLAN_UPDATE';
export const PLAN_UPDATE_SUCCESS = 'PLAN_UPDATE_SUCCESS';
export const PLAN_UPDATE_ERROR = 'PLAN_UPDATE_ERROR';

export const PLAN_DELETE = 'PLAN_DELETE';
export const PLAN_DELETE_SUCCESS = 'PLAN_DELETE_SUCCESS';
export const PLAN_DELETE_ERROR = 'PLAN_DELETE_ERROR';
