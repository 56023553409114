<template>
  <BaseLayout :title="title">
    <template #actions>
      <InputSearch
        v-model="search"
        :placeholder="$t('placeholder.searchUser')"
        @input="onSearch"
        @clear="onClearSearch"
        class="form-group-rounded mb-0"
      />
      <InlineButton @click="onDownload" :loading="isDownloading" pill shadow variant="secondary">
        {{ $t('text.export') }}
      </InlineButton>
    </template>

    <CustomerTable
      :list="customerList"
      :current-page="currentPage"
      :meta="customerListMeta"
      :is-loading="isLoading"
      @onShowDetails="onShowDetails"
      @edit="onEdit"
      @changeSubscription="onChangeSubscription"
      @onChangePage="onChangePage"
    />

    <CustomerAddEditModal
      :form-data="dataToEdit"
      :modal-id="editModalId"
      @onHidden="onHidden"
      @onRemove="onConfirmDelete"
    />

    <CustomerSubscriptionUpdate
      :form-data="dataToEdit"
      :modal-id="changeSubscriptionModalId"
      @onHidden="onHidden"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseLayout from '@/components/ui/BaseLayout';
import { CUSTOMER_LIST_REQUEST, CUSTOMER_DELETE, CUSTOMER_EXPORT } from '@/store/actions/customer';
import CustomerTable from '@/components/manage/customers/CustomerTable';
import { KEY_FILTER_Q, KEY_FILTER_IS_ADMIN } from '@/utils/filter';
import { DEBOUNCE_MILLISECONDS } from '@/config';
import InputSearch from '@/components/ui/input/InputSearch';
import InlineButton from '@/components/ui/button/InlineButton';
import CustomerAddEditModal from '@/components/manage/customers/CustomerAddEditModal';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import CustomerSubscriptionUpdate from '@/components/manage/customers/CustomerSubscriptionUpdate';

export default {
  props: {
    title: String,
  },
  components: {
    BaseLayout,
    CustomerTable,
    InputSearch,
    InlineButton,
    CustomerAddEditModal,
    ConfirmDialog,
    CustomerSubscriptionUpdate,
  },
  data() {
    return {
      search: '',
      currentPage: 1,
      dataIdToEdit: null,
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
      changeSubscriptionModalId: 'manage-change-subscription-modal',
    };
  },
  computed: {
    ...mapGetters([
      'customerStatus',
      'customerList',
      'customerListMeta',
      'customerListStatus',
      'getCustomerById',
      'customerExportStatus',
      'customerExportLink',
    ]),
    dataToEdit() {
      return this.dataIdToEdit ? this.getCustomerById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.customerListStatus === 'loading';
    },
    isDownloading() {
      return this.customerExportStatus === 'downloading';
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData(search) {
      const payload = {
        limit: 30,
        page: search ? 1 : this.currentPage,
        filter: {},
      };
      payload.filter[`${KEY_FILTER_IS_ADMIN}`] = 0;

      if (this.search) {
        payload.filter[`${KEY_FILTER_Q}`] = this.search;
      }
      await this.$store.dispatch(CUSTOMER_LIST_REQUEST, payload);
    },
    async onDownload() {
      const payload = {
        limit: 100,
        page: this.search ? 1 : this.currentPage,
        filter: {},
      };

      payload.filter[`${KEY_FILTER_IS_ADMIN}`] = 0;

      if (this.search) {
        payload.filter[`${KEY_FILTER_Q}`] = this.search;
      }

      await this.$store.dispatch(CUSTOMER_EXPORT, payload);
      if (this.customerExportStatus === 'success') {
        window.open(this.customerExportLink, '_blank');
      }
    },
    onClearSearch() {
      this.search = '';
      this.fetchData();
    },
    onSearch() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchData(true);
      }, DEBOUNCE_MILLISECONDS);
    },
    onShowDetails(customerId) {
      this.$emit('showDetails', customerId);
    },
    emptyingDataToEdit() {
      this.dataIdToEdit = null;
    },
    onEdit(id) {
      this.dataIdToEdit = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(CUSTOMER_DELETE, this.dataIdToRemove);
      if (this.staffStatus === 'deleted') {
        this.$bvModal.hide(this.editModalId);
        this.dataIdToRemove = null;
      }
    },
    onHidden() {
      this.emptyingDataToEdit();
      this.fetchData();
    },
    onChangeSubscription(id) {
      this.dataIdToEdit = id;
      this.openModalChangeSubscription();
    },
    openModalChangeSubscription() {
      this.$bvModal.show(this.changeSubscriptionModalId);
    },
  },
};
</script>
