<template>
  <ModalMain
    :id="modalId"
    title="Эрх өөрчлөх"
    ok-title="Хадгалах"
    :loading="isLoading"
    delete-disabled
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
  >
    <b-form @submit.prevent="onSave">
      <InputDatepicker
        class="customer-ends-at"
        :label="$t('label.endsAt')"
        size="lg"
        v-model="$v.form.date.$model"
        :vuelidate="$v.form.date"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { CHANGE_SUBSCRIPTION } from '@/store/actions/customer';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputDatepicker from '@/components/ui/input/InputDatepicker';

const defaultValuesForForm = () => ({
  date: '',
  userId: null,
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations() {
    return {
      form: {
        date: {
          required,
        },
      },
    };
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['changeSubscriptionStatus']),
    isLoading() {
      return this.changeSubscriptionStatus === 'loading';
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            userId: this.formData.id,
            date: this.formData.endsAt,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      await this.$store.dispatch(CHANGE_SUBSCRIPTION, this.form);

      if (this.changeSubscriptionStatus === 'success') {
        this.hideEditModal();
      }
    },
  },

  components: {
    ModalMain,
    InputDatepicker,
  },
};
</script>
