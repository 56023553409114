<template>
  <TableBox :current-page="currentPage" :meta="meta" @onChangePage="$emit('onChangePage', $event)">
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="list"
      :busy="isLoading"
      class="custom-table"
      thead-class="custom-table-header"
      tbody-tr-class="custom-table-body"
      :emptyText="$t('text.emptyText')"
      show-empty
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(name)="data">
        <p class="text-truncate" style="max-width: 200px">{{ data.item.name }}</p>
      </template>

      <template #cell(isFree)="data">
        <p v-if="data.item.isFree" class="text-success">Үнэгүй</p>
      </template>

      <template #cell(category)="data">
        <p class="text-truncate" style="max-width: 150px">
          {{ data.item.category ? data.item.category.name : '' }}
        </p>
      </template>

      <template #cell(action)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            @click="$emit('edit', data.item.id)"
            class="has-shadow"
          >
            {{ $t('text.editButton') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>

<script>
import TableBox from '@/components/ui/TableBox';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    list: Array,
    meta: Object,
    currentPage: Number,
    isLoading: Boolean,
  },

  data() {
    return {
      fields: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: this.$t('table.th.name'),
        },
        {
          key: 'category',
          label: this.$t('table.th.category'),
        },
        {
          key: 'isFree',
          label: 'Үнэгүй эсэх',
        },
        {
          key: 'action',
          label: this.$t('table.th.settings'),
        },
      ],
    };
  },

  components: {
    TableBox,
    InlineButton,
  },
};
</script>
